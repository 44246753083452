/*
   @description pathString 에 따라 json 객체 내의 key 를 쫒아가 value 를 반환하는 함수
   @param {object} obj - 쫒아갈 객체
   @param {string} pathString - 쫒아갈 화살표 경로 ex) 'a->b->c'
   규칙
     - 객체만 처리 가능
     - 키의 value 가 객체이면 타고 들어감
     - 객체는 화살표로 key 값 연결. ex) 'a->b->c' 는 a 키의 value 를 찾음, value 는 object 임, 이 객체의 b 키의 value 를 찾음, 이 객체의 c 키의 value 를 찾음... 으로 탐색함. 만약 키의 value 가 객체가 아니고 값이면 에러 발생
     - 객체 key 탐색할 때 ? 를 사용해서 undefined 일 경우에 대한 처리. undefined 인 경우 에러를 발생시키지 않고 탐색 중단하고 undefined 을 리턴. ex) a?->b->c 인 경우 a 키의 밸류를 찾는다, b 키는 없을 수도 있다. ? 를 쓰지 않았으면 에러를 내는데 ? 가 있기 때문에 undefined 을 리턴
     - 키의 value 가 배열이면 배열의 index 로 처리. key[number] 형식일때 index 로 찾음. ex) a[0]->b[1]->c[2] 인 경우 a 키의 value 를 찾는다, value 는 배열임, 이 배열의 0번째 값을 찾는다, 이 값의 b 키의 value 를 찾는다, 이 값의 c 키의 value 를 찾는다... 으로 탐색함. 만약 키의 value 가 배열이 아니면 에러 발생
     - 배열을 탐색할 때 해당 index 가 없는 경우 error 발생
     - 배열은 n 차원 배열까지 index 를 지원. ex) a[0][1][2] -> b[0] 형식
     - 키의 value 가 배열인데 배열 요소의 value 가 object 인 경우 object 를 find 하는 형태로 처리. key[{id: 323, type: 'product'} | {type: 'cart'}] 형식이면 key 의 배열 값을 find 로 순회해서 id: 323 && type: 'product' 인 값을 찾는형태. [] 안에 | 기호는 객체의 key value 조건을 or 조건으로 탐색
   @returns {any} 쫒아간 끝 value
*/
export function pathFinder(obj, pathString) {
  if (!obj || !pathString) return undefined;

  const paths = pathString.split('->');
  let current = obj;

  // 객체 조건 문자열을 파싱하는 함수 추가
  const parseCondition = (conditionStr) => {
    // 작은따옴표를 큰따옴표로 변환하고 공백 제거
    const cleanStr = conditionStr.trim().replace(/'/g, '"');
    try {
      // 속성 이름에도 큰따옴표 추가
      const jsonStr = cleanStr.replace(/([{,]\s*)(\w+):/g, '$1"$2":');
      return JSON.parse(jsonStr);
    } catch (e) {
      throw new Error(`Invalid condition format: ${conditionStr}`);
    }
  };

  for (const path of paths) {
    if (!current) return undefined;

    // 배열 접근 패턴 체크 (예: key[0] 또는 key[{id: 1}])
    const arrayMatch = path.match(/^(\w+)(\[.+\])+$/);
    if (arrayMatch) {
      const key = arrayMatch[1];
      const keyPart = path.slice(key.length);
      const arrayAccessors = keyPart.match(/\[([^\]]+)\]/g);

      current = current[key];
      if (!Array.isArray(current)) {
        throw new Error(`${key}는 배열이 아닙니다`);
      }

      for (const accessor of arrayAccessors) {
        const innerValue = accessor.slice(1, -1);

        // 객체 조건으로 검색하는 경우
        if (innerValue.startsWith('{')) {
          const conditions = innerValue.split('|').map((condition) => parseCondition(condition.trim()));

          current = current.find((item) =>
            conditions.some((condition) => Object.entries(condition).every(([k, v]) => item[k] === v)),
          );
        }
        // 숫자 인덱스로 접근하는 경우
        else {
          const index = parseInt(innerValue);
          if (index >= current.length) {
            throw new Error(`배열 인덱스 ${index}가 범위를 벗어났습니다`);
          }
          current = current[index];
        }
      }
      continue;
    }

    // optional chaining 체크 (예: a?)
    const optionalMatch = path.match(/^(\w+)\?$/);
    if (optionalMatch) {
      const key = optionalMatch[1];
      current = current[key];
      if (current === undefined) return undefined;
      continue;
    }

    // 일반 객체 접근
    if (current[path] === undefined && !path.endsWith('?')) {
      throw new Error(`${path} 키를 찾을 수 없습니다`);
    }
    current = current[path];
  }

  return current;
}

/**
 * pathString에서 동적 값을 대체하는 함수
 * @param {string} pathString - 경로 문자열 (예: "a->b[0]->:character->:age[2]")
 * @param {Object} replacements - 대체할 값들을 담은 객체
 * @returns {string} 대체된 경로 문자열
 * @throws {Error} pathString에 있는 모든 콜론(:) 값이 replacements에 없을 경우
 */
export function makePathFinderString(pathString, replacements) {
  // 콜론으로 시작하는 모든 키를 찾음
  const colonKeys = pathString.match(/:([a-zA-Z]+)/g) || [];

  // 콜론을 제거하고 실제 키 이름만 추출
  const requiredKeys = colonKeys.map((key) => key.substring(1));

  // replacements 객체에 필요한 모든 키가 있는지 확인
  const missingKeys = requiredKeys.filter((key) => !(key in replacements));

  if (missingKeys.length > 0) {
    throw new Error(`다음 키들이 replacements 객체에 없습니다: ${missingKeys.join(', ')}`);
  }

  // 모든 콜론 키를 해당하는 값으로 대체
  let resultPath = pathString;
  requiredKeys.forEach((key) => {
    const regex = new RegExp(`:${key}`, 'g');
    resultPath = resultPath.replace(regex, replacements[key]);
  });

  return resultPath;
}
