export default {
  home: {
    home_1_0: '단 한 개도 만들 수 있어요!',
    home_1_1: '단체 굿즈도 합리적인 가격으로 만들어 보세요!',
    home_1_2: '단체 굿즈도 \r합리적인 가격으로!',
    home_2_1: '재구매가 많은 상품들 \r모아봤어요',
    home_2_2: '베스트 상품 전체보기',
    home_3_1: '마플러들의 실시간 리뷰',
    home_4_1: '{{count}}개의 새로운 \r상품이 도착했어요!',
    home_4_2: '요즘 제일 많이 커스텀 해요',
    home_5_1: '마플이 엄선한 하이퀄리티 브랜드',
    home_6_1: '요즘 인기 커스텀, \r당신도 할 수 있어요!',
    home_7_1: '{{cate_list_name}} 더보기',
  },

  keyword: {
    kwd_new: '{{month}}월 신상품',
    kwd_best: '마플 베스트',
    kwd_1_1: '연말 선물',
    kwd_1_2: '1개부터 만들기',
    kwd_1_3: '기업 굿즈',
    kwd_1_4: '팬 굿즈',
    kwd_1_5: '판촉상품',
    kwd_1_6: 'ESG 굿즈',
    kwd_1_7: '단체 굿즈',
    kwd_1_8: '싱글 스티커',
    kwd_1_9: '떡메모지',
    kwd_1_10: '여행 굿즈',
    kwd_1_11: '폰꾸미기',
    kwd_1_12: '다꾸 굿즈',
    kwd_1_13: '데스크테리어',
  },
  url: {
    url_1: '/search/products?q=%EB%8B%A8%EC%B2%B4%20%EA%B5%BF%EC%A6%88',
    url_2: '/search/all?q=여행굿즈',
    url_3: '/search/all?q=다꾸',
    url_4: '/search/all?q=데스크',
    url_5: '/search/products?q=%EC%B9%B4%ED%8E%98',
  },

  navigation: {
    navigation_01: '원~',
    navigation_02: '전체상품',
    navigation_03: ' 카테고리',
  },
  pro_editor: {
    alert: {
      title: {
        file_size: '파일 크기를 확인해 주세요.',
        file_save: '저장 방식을 확인해 주세요.',
        template_use: '지정된 템플릿을 활용해 주세요.',
        layer: '레이어를 확인해 주세요.',
        layer_stand: 'stand 레이어를 확인해 주세요.',
        layer_ring: 'ring 레이어를 확인해 주세요.',
        layer_cut: 'cut 레이어를 확인해 주세요.',
        layer_cut_not_single: '칼선 path를 확인해 주세요.',
        layer_cut_not_path_type: '칼선 path를 확인해 주세요.',
        layer_cut_not_closed: '칼선 path를 확인해 주세요.',
        art_board: '아트보드를 확인해 주세요.',
        download: '파일 다운로드 중<br>오류가 발생했습니다.',
        unknown: '알 수 없는 에러가 발생했습니다.<br>고객센터를 통해 문의 부탁드립니다.',
        dev: '파일 분석 중 에러가 발생했습니다.<br>고객센터를 통해 문의 부탁드립니다.',
      },
      text: {
        file_size: ' 이하의 SVG 파일만 업로드 하실 수 있습니다.',
        file_save: '파일 저장시 SVG Options 에서<br>Responsive 체크를 해제해 주세요.',
        template_use:
          '템플릿을 다운로드 후 도안을 제작해 업로드 해주세요.<br>가이드에 준수하지 않은 SVG파일은 업로드 하실 수 없습니다.',
        layer:
          '템플릿 파일의 레이어를 추가, 삭제하시거나<br>레이어명을 임의로 변경하실 경우 파일이 업로드 되지 않습니다.',
        layer_stand:
          '기본 제공되는 사각형 path를 추가, 삭제하시거나<br>다른 요소를 추가하실 경우 파일이 업로드 되지 않습니다.',
        layer_ring:
          '기본 제공되는 ring path를 추가, 삭제, 변형하시거나<br>다른 요소를 추가하실 경우 파일이 업로드 되지 않습니다.',
        layer_cut:
          '칼선 path는 끊어지지 않고 하나의 선으로 연결되어 있어야 하며<br>칼선 path를 제작하지 않으시면 파일이 업로드 되지 않습니다.',
        layer_cut_not_single:
          '칼선 path는 한 개만 넣어주셔야 하며,<br>칼선 path가 없거나 두 개 이상 넣으시면 파일이 업로드 되지 않습니다.',
        layer_cut_not_path_type:
          '제작하신 칼선을 Compound Path로 변경해 주세요.<br>일러스트레이터에서 칼선 Path 우클릭 → Make Compound Path',
        layer_cut_not_closed: '칼선 path는 끊어지지 않은 하나의 선으로 연결해 주세요.',
        art_board:
          '기본 제공되는 Artboard 사이즈는 변경이 불가능하며<br>모든 디자인이 Artboard 내부에 들어올 수 있도록 해주세요',
      },
    },
  },

  review: {
    review_01: `마플러들의 실시간 리뷰`,
    review_02: '포토리뷰 모아보기',
    review_03: '모든 상품',
    review_04: '더보기',
    review_05: 'More Review ({{count}})',
    detail: {
      detail_01: '리뷰 상세 👀',
    },
    write: {
      write_01: '리뷰 작성하기 ✍️',
      write_02: '리뷰 작성 완료',
      write_03: '상품평을 작성해 주세요',
      write_04: `마플에서의 커스텀 경험, 만드신 상품에 대한 리뷰 부탁드립니다.\n멋진 후기를 작성해 주시면 포인트를 드려요!`,
      write_04_01: `마플에서의 커스텀 경험, 만드신 상품에 대한 리뷰 부탁드립니다. 멋진 후기를 작성해 주시면 포인트를 드려요!`,
      write_05: 'SNS 공유',
      write_06:
        '본인의 SNS에 <span style="color: #FF6B00">#마플 #마플커스텀 #내가만든커스텀</span> 해시태그와 함께 사진 후기를 올려주시면 선정 후<span style="color: #FF6B00; padding: 0 4px;">300P</span>추가로 적립해드립니다!<br />해시태그가 없거나 잘못된 링크, 비공개 계정은 포인트 적립이 어렵습니다.',
      write_07: 'SNS에 공유하신 링크를 입력해 주세요.',
      write_08: '작성하신 리뷰는 마플 홍보 콘텐츠로 활용될 수 있습니다. (필수)',
      write_09: '상품은 어떠셨나요? 별점을 남겨주세요.',
      write_10: '리뷰를 작성해주셔서 감사합니다.',
      write_11: '(최소 5자 이상 작성)',
      write_06_01:
        '본인의 SNS에 <span style="color: #FF6B00">#마플 #마플커스텀 #내가만든커스텀</span><br>해시태그와 함께 사진 후기를 올려주시면 선정 후,<br><span style="color: #FF6B00; padding-right: 4px;">300P</span>추가로 적립해드립니다!<br><p style="margin:0; padding-top: 4px;">해시태그가 없거나 잘못된 링크, 비공개 계정은<br>포인트 적립이 어렵습니다.</p>',
      write_08_01: '작성하신 리뷰는 마플 홍보 콘텐츠로<br>활용될 수 있습니다. (필수)',
      write_12: "리뷰를 작성하시면 '배송완료'로 변경됩니다.",
      write_13: 'No. {{no}}',
    },
    review_06: '지금 핫한 리뷰',
    review_07: '전체 리뷰 보기',
    review_08: '지금 리뷰 쓰고 포인트 받아가세요 🎉',
  },
  cell: {
    review: {
      read_more: 'Read more',
      category_more: '{{name}} 더보기',
      size_info: '{{size}} 구매',
      acc_purchase: '{{count}}번째 구매',
      detail_review: '리뷰 상세보기',
      more_products: '다른 고객이 함께 본 상품이에요',
      more_products_mo: '다른 고객이 <br>함께 본 상품이에요',
      customizing: '커스텀하기',
      view_more_products: '{{cate_list_name}} 상품 더보기',
      latest_review: '실시간 리뷰',
      view_more_reviews: '{{cate_list_name}} 후기 더보기',
      review_count: '{{count}}개&nbsp',
    },
    product: {
      prod_1_1: '평점',
      prod_1_2: '리뷰',
      prod_1_3: '단 1개도 주문 가능 !',
      prod_1_4: '{{count}}장 마다 추가로 {{price}}원씩 할인',
      prod_1_5: '{{n}}개',
      prod_1_6: '{{n}}개 이상',
      prod_1_7: '{{n}}% 할인',
      prod_1_8: '정가',
      prod_1_9: '{{n}}개 이상 제작 가능',
      prod_1_10: '별도문의',
    },
  },
  search: {
    search_01: '2,000여 개의 커스텀 상품을 쉽게 찾아보세요',
    search_02: '추천 검색어',
    search_03: '모든 커스텀을 마플에서',
    search_04:
      '<span style="color: #ff6b00">{{keyword}}</span>에 대한 <span style="color: #ff6b00">{{count}}개</span>의{{- br}}검색 결과가 있어요.',
    search_07: '최근 검색어',
    search_09: '검색어가 정확한지 확인해 주세요.{{- br}}특정 단어가 아닌 상품명을 입력해 보세요.',
    search_10: '최근 검색어 내역이 없습니다.',
    search_11: '통합',
    search_12: '상품',
    search_13: '콘텐츠',
    search_14: '리뷰',
    search_15: '상품 더보기',
    search_16: '커스텀 노하우',
    search_17: '마플러들의 실시간 리뷰',
    search_18: '상품이 없습니다.',
    search_19: '콘텐츠가 없습니다.',
    search_20: '리뷰가 없습니다.',
  },
  // README `common`은 정말 제너럴하게 쓰일 것 같은 key만 "의미있는 이름으로" 추가합니다.
  common: {
    show_all: '전체보기',
    remove_all: '모두 지우기',
    currency: '{{price}}원',
    quantity: '{{quantity}}개',
    view_more: '더보기',
    delete: '삭제',
    review_count: '후기 {{count}}',
    show_all_name: '{{name}} 전체보기',
  },
  omp_layout: {
    left: {
      all_product: '전체상품',
      left_01: '1개부터 제작',
      left_01_mo: '1개부터 제작',
      left_02: '신상품 / 베스트',
      left_03: '전체 상품',
      left_04: '기업 굿즈 상담',
      left_04_mo: '기업 굿즈',
      left_05: '실시간 리뷰',
      left_06: '커스텀 노하우',
      left_07: '리뷰',
      left_08: '단체굿즈/판촉',
      left_08_01: '단체굿즈',
      left_09: '포트폴리오',
      sub_01: '회원가입',
      sub_02: '로그인',
      sub_03: '1:1 문의',
      sub_04: '고객센터',
      sub_05: '처음 오셨나요?',
      sub_06: '마이페이지',
      sub_07: '로그아웃',
      sub_08: '단체주문',
    },
    bottom_bar: {
      txt_01: '홈',
      txt_02: '전체 상품',
      txt_03: '단체굿즈/판촉',
      txt_04: '리뷰',
      txt_05: '마이',
    },
    mp_title: '마플 커스텀 {{cate_list_name}}',
    title: '마플',
    sub_menu_all: '전체 상품',
    sub_menu_all_mo: '전체',
    temp_cate_list_name: '디지털/테크',
  },
  auth: {
    login: {
      login_01: '로그인',
      login_02: '이메일 주소',
      login_03: '비밀번호',
      login_04: '아이디 찾기',
      login_05: '비밀번호 재설정',
      login_01_01: '페이스북으로 로그인',
      result: '잠시만 기다려 주세요!',
      welcome: '만나서 반가워요!',
      welcome_message:
        'MCO 통합계정 가입이 완료되었어요.<br/>단 하나뿐인 커스텀 굿즈부터<br/>우리 브랜드에 필요한 단체 상품까지<br/>마플에서 모두 만들어 보세요!',
      welcome_message_sns:
        'MCO 통합계정 연결이 완료되었어요.<br/>단 하나뿐인 커스텀 굿즈부터<br/>우리 브랜드에 필요한 단체 상품까지<br/>마플에서 모두 만들어 보세요!',
      modal: {
        title: '로그인하고 계속 진행하기',
        description: 'MCO 회원만이 누릴 수 있는 특별한 혜택이 있어요!',
        signup: '회원가입하기',
        signin: '로그인하기',
      },
      error: {
        title: '로그인 중 오류가 발생했어요.',
        description: '잠시 후 다시 시도하거나,<br />고객 지원팀에 문의해 주세요.',
        contact: '고객 센터 1566-5496',
      },
      result_description: '요청하신 페이지로 이동중입니다.',
    },
    signup: {
      signup_01: '회원가입',
      signup_02: '이름',
      signup_03: '이메일 주소',
      signup_04: '비밀번호 (10~64자)',
      signup_05: '이용약관 동의 (필수)',
      signup_06: '개인정보수집 및 이용 동의 (필수)',
      signup_07: '만 14세 이상입니다 (필수)',
      signup_08: '이메일 마케팅 수신동의 (선택)',
      signup_09: '보기',
      signup_01_01: '페이스북으로 시작하기',
      signup_11: '전체 동의',
    },
    reset_password: {
      reset_password_01: '비밀번호 재설정',
      reset_password_02: '이메일',
      reset_password_03: '이메일 주소를 입력해 주세요.',
      reset_password_04: '비밀번호 재설정 메일 전송',
      reset_password_05: '비밀번호 변경 이메일 받기',
      reset_password_06:
        '입력하신 주소의 이메일로 비밀번호 변경 안내 메일을 전송했습니다.<br>안내 메일이 몇 분 내에 나타나지 않으면 스팸메일함을 확인해 주세요.',
      reset_password_07: '',
      reset_password_08: '비밀번호',
      reset_password_10: '10자 이상 / 영문, 숫자, 특수문자 포함',
      reset_password_11: '비밀번호 확인',
      reset_password_12: '비밀번호를 재입력 해주세요.',
    },
    find_id: {
      find_id_01: '아이디 찾기',
      find_id_02: '휴대폰 인증',
      find_id_03: '이름',
      find_id_04: '이름을 입력해 주세요.',
      find_id_05: '연락처',
      find_id_06: '연락처를 입력해 주세요.',
      find_id_07: '인증번호 요청',
      find_id_08: '인증번호 6자리를 입력해주세요.',
      find_id_09: '인증번호 재전송',
      find_id_10: '아이디 찾기 결과',
      find_id_11: '회원님의 정보가 일치하는 아이디 목록입니다.',
      find_id_12: '이메일 없음',
      find_id_13: '페이스북 가입 계정',
    },
    email_verify: {
      email_verify_01: `인증 이메일을 보냈습니다.<br>이메일을 확인하고, 인증하기 버튼을 클릭하시면 인증이 완료됩니다.`,
      email_verify_02: '인증 이메일을 못 받으셨나요?',
      email_verify_03: '인증 이메일 다시 보내기',
      email_verify_04: '이메일 인증하기',
      email_verify_05: '이메일을 확인해 주세요.',
      email_verify_06: '몇 분 안에 나타나지 않으면,<br>스팸 폴더를 확인해 주세요.',
      email_verify_07: '환영합니다!<br>이메일 인증이 완료되었습니다.',
      email_verify_08: '이메일이 인증되지 않았습니다.',
    },
    need_verify: {
      need_verify_01: '인증하기 버튼을 클릭하시면 인증이 완료됩니다.',
      need_verify_02: '이메일 인증이 필요합니다.',
      need_verify_03: '이메일 변경',
    },
    change_email: {
      change_email_01: '이메일 변경하기',
      change_email_02: '현재 입력된 이메일',
      change_email_03: '새로운 이메일을 입력해주세요',
      change_email_04: '기존 비밀번호를 입력해주세요',
      change_email_05: '이메일 변경',
      change_email_06: '변경되었습니다.',
    },
    error: {
      login_001_title: 'state와 session_id가 일치하지 않는 경우',
      login_001_message: 'state와 session_id가 일치하지 않는 경우',
      login_002_title: '인증서버에서 email, password를 사용한 로그인이 실패한 경우',
      login_002_message: '인증서버에서 email, password를 사용한 로그인이 실패한 경우',
      login_003_title: '로그인이 필요하지만 로그인을 하지 않은 경우',
      login_003_message: '로그인이 필요하지만 로그인을 하지 않은 경우',
      login_004_title: '로그인을 한 상태이지만 존재하지 않는 user 인 경우',
      login_004_message: '로그인을 한 상태이지만 존재하지 않는 user 인 경우',
      signup_001_title: '회원가입 실패',
      signup_001_message:
        '예기치 못한 오류로 인해 가입할 수 없습니다.<br/>동일한 문제가 반복적으로 발생할 경우<br/><a href="mailto:cs@marppleshop.com">cs@marppleshop.com</a>로 문의해 주세요.',
      login_005_title: '인증서버를 통한 프로필 갱신에 실패한 경우 (기존 session에 user 정보가 없는 경우)',
      login_005_message: '인증서버를 통한 프로필 갱신에 실패한 경우 (기존 session에 user 정보가 없는 경우)',
    },
    cart: {
      confirm: {
        title: '상품을 장바구니에 담았습니다.',
        content: '장바구니를 확인하시겠어요?',
        ok: '장바구니로 이동',
        cancel: '계속 커스텀하기',
      },
    },
  },
  error: {
    common: {
      error_01: '오류가 발생했습니다.',
    },
    auth: {
      error_01: '10자 이상 입력해주세요. / 영문, 숫자, 특수문자 사용 가능',
      error_03: '동일한 문자를 과도하게 연속해서 사용할 수 없습니다.',
      error_02: '보안을 위해 연속된 문자를 사용할 수 없습니다.',
      error_04: '영문, 숫자, 특수문자를 조합하여 설정해주세요.',
      error_05: '이메일 형식을 확인해 주세요.',
      error_06: '이름을 입력하여 주십시오.',
      error_06_01: '이름은 특수문자를 포함할 수 없습니다.',
      error_07: '이용약관에 동의해 주세요.',
      error_08: '개인정보수집 이용에 동의해 주세요.',
      error_09: '만 14세 이상 가입이 가능합니다.',
      error_10: '비밀번호에 ID를 포함할 수 없습니다.',
      error_11: '탈퇴한 아이디 입니다.',
      error_12: '이미 가입된 아이디 입니다.',
      error_13: '회원가입을 실패하였습니다.',
      error_14: '이메일 또는 비밀번호를<br>잘못 입력했습니다.<br>입력하신 내용을<br>다시 확인해 주세요.',
      error_15: '비밀번호 재설정 불가',
      error_15_01: '인증코드가 유효하지 않습니다.',
      error_16: '비밀번호 재설정 메일을 보낼 수 없습니다.<br/>메일 주소를 확인해주세요.',
      error_17: '비밀번호 재설정 이메일 전송실패',
      error_18: '비밀번호 재설정 메일을 1분 이내에<br/>재전송 할 수 없습니다.<br/>잠시 후 다시 시도해주세요.',
      error_19: '코드가 생성되지 않았습니다.',
      error_20: '메일 전송 오류',
      error_21: '네트워크 오류가 발생했습니다.<br/>잠시 후 다시 시도해주세요.',
      error_22: '비밀번호를 입력해 주세요.',
      error_23: '비밀번호가 일치하지 않습니다.',
      error_24: '비밀번호 확인이 비어있습니다.',
      error_25: '비밀번호가 비어있습니다.',
      error_26: '현재 비밀번호와 동일한 비밀번호는<br/>사용할 수 없습니다.',
      error_15_02: '입력하신 정보와 일치하는 계정이 없습니다.',
      error_27: '비밀번호 재설정 완료',
      error_27_01: '비밀번호가 변경되었습니다.',
      error_28: '인증번호 전송 불가',
      error_28_01: '인증번호를 전송할 수 없습니다<br/>연락처를 확인해주세요.',
      error_29: '인증번호 6자리를 입력해 주세요.',
      error_30: '핸드폰 번호 형식이 아닙니다.',
      error_31: '아이디를 찾을 수 없습니다.',
      error_32: '인증번호 재전송 불가',
      error_33: '전송이 실패되었습니다.',
      error_33_01: '잠시 후 다시 시도해주세요.',
      error_34: '인증 실패',
      error_34_01: '인증번호가 일치하지 않습니다.',
      error_35: '페이스북 로그인 로딩이 느립니다. 잠시 후 다시 시도해주세요.',
      error_36: '이메일을 선택해주세요.',
    },
    code: {
      code_401: '로그인이 필요합니다.',
      code_400: '잘못된 요청입니다.',
    },
    review: {
      write_01: '업로드 가능한 이미지 파일은 JPEG 또는 PNG, GIF 파일입니다.',
      write_02: '{{max}}장 이하로 올려주세요.',
      write_03: '파일 용량이 너무 큽니다.<br>{{mb}}MB 이하로 업로드해 주세요.',
      write_04: '최소 5글자 이상 작성해 주세요.',
      write_05: '최대 2000글자 이하로 작성해 주세요.',
      write_06: '리뷰 활용 약관에 동의해 주세요.',
      write_07: 'SNS 주소가 잘못되었습니다. 확인해 주세요.',
      write_08: '구매이력을 확인할 수 없습니다.',
      write_09: '데이터가 유효하지 않습니다.',
    },
    order: {
      order_01: '구매이력을 확인할 수 없습니다.',
    },
    wow: {
      purchase1: `이용에 불편을 드려 죄송합니다.<br>
      {{bp_names}}의 주문이 일시적으로 원활하지 않아 결제가 취소되었습니다.<br>
      해당 상품을 제외하고 주문을 시도해 주세요.<br>
      주문 실패가 계속되면 고객센터(1566-9437)나 오른쪽 하단 웹챗으로 연락 주세요. [{{target}}]`,
    },
    general: {
      '제작 진행중인 상품이 있어 취소를 요청할 수 없습니다.오른쪽 하단 웹챗이나 고객센터(1566-9437)로 문의주세요.':
        '제작 진행중인 상품이 있어 취소를 요청할 수 없습니다.<br>오른쪽 하단 웹챗이나 고객센터(1566-9437)로 문의주세요.',
    },
  },
  product: {
    list: {
      list_1_1: '브랜드',
      list_2_1: '색상',
      list_3_1: '사이즈',
      list_4_1: '추천순',
      list_5_1: '낮은 가격순',
      list_6_1: '높은 가격순',
      list_7_1: '필터 검색',
      list_8_1: '{{- checked_cate_list_name}}{{end_with}} 커스텀 해보세요',
      list_9_1: '전체상품',
      list_10_1: '마플 {{name}} 1위',
      list_11_1: '누적 판매량 9,999+',
      list_11_2: '1~2도 인쇄',
      list_12_1: '{{price}}원 부터',
      list_13_1: '{{price}}원',
      list_14_1: '확인',
      list_15_1: '카테고리 선택하기',
      list_16_1: '필터',
      list_17_1: '필터에 적합한 상품이 없습니다.',
      list_18_1: '원하는 필터를 선택해 주세요.',
      list_19_1: '상품보기',
      list_20_1: '초기화',
    },
    new_best: {
      new_best_1_1: '이 상품 커스텀하기',
      new_best_2_1: '새로운 커스텀을 경험해 보세요',
      new_best_3_1: '이 아이템도 커스텀이 가능해요',
      new_best_4_1: '요즘 제일 많이 커스텀해요',
      new_best_5_1: '커스텀의 정석, 베스트 아이템',
      new_best_6_1: '신상품',
      new_best_7_1: '베스트',
      new_best_8_1: '단체 베스트',
      new_best_8_2: '단체 고객님을 위한 추천 아이템',
      new_best_8_3: '꾸준히 인기 있는, 단체 베스트 아이템',
    },
    custom: {
      start: '이 상품 커스텀하기',
    },
  },
  my_page: {
    layout: {
      layout_01: '마이페이지',
    },
    order: {
      order_00: '전체',
      order_01: '구매내역',
      order_02: '결제 확인',
      order_03: '주문이 접수되었습니다.<br>결제 확인 후 다음단계로 진행됩니다.',
      order_04: '결제 완료',
      order_05: '입금이 확인되었습니다.',
      order_06: '제작 준비중',
      order_07: '주문하신 커스텀 사양을 확인하고,<br>제작을 준비하고 있습니다.',
      order_08: '제작중',
      order_09: '주문하신 커스텀 상품을<br>제작하고 있습니다.',
      order_10: '배송 준비중',
      order_11: '상품 배송을 준비하고 있습니다.',
      order_12: '배송중',
      order_13: '물품이 발송되어<br>고객님께 배송중입니다.',
      order_14: '배송 완료',
      order_15: '배송이 완료되었습니다.<br>배송 완료일 포함 7일 이내<br>교환 / 반품신청이 가능합니다.',
      order_16: '최근 1개월 구매내역',
      order_17: '주문일자 / 주문번호',
      order_18: '구매 상품정보',
      order_19: '금액',
      order_20: '진행상태',
      order_21: '구매',
      order_22: '최근 1개월 내 구매내역이 없습니다.',
      order_01_01: '주문 취소',
      order_01_02: '주문 취소 요청',
      order_01_03: '취소된 주문입니다.',
      order_23: '주문하신 상품이 없습니다.',
      order_23_01: '{{status}} 상품이 없습니다.',
      order_24: '결제 확인 중입니다.',
      order_25: '제작 준비 중입니다.',
      order_26: '제작 중입니다.',
      order_27: '배송 준비 중입니다.',
      order_28: '배송 중입니다.',
      order_29: '배송이 완료되었습니다.',
      order_30: '주문번호 {{order_id}}',
      detail: {
        detail_01: 'Step {{step}}',
        detail_02: '결제 수단 변경하기',
        detail_03: '주문 취소하기',
        detail_04: '추가 결제하기',
        detail_05: '주문 상품 정보',
        detail_07: '구매 상품정보',
        detail_06: '사이즈',
        detail_08: '색상',
        detail_09: '수량',
        detail_10: '금액',
        detail_11: 'No.{{up_c_id}}',
        detail_12: '인쇄 : {{print_face}}',
        detail_12_01: '인쇄',
        detail_13: '주문정보 엑셀 다운',
        detail_14: '거래명세서 인쇄',
        detail_15: '제작 요청사항',
        detail_16: '배송지 정보',
        detail_17: '총 결제 금액',
        detail_18: '결제 정보',
        detail_19: '특별 요청 사항이 없습니다.',
        detail_20: '총 상품 금액',
        detail_21: '총 배송비',
        detail_21_01: '도서산간비',
        detail_22: '쿠폰',
        detail_23: '포인트',
        detail_24: '추가 금액',
        detail_25: '추가 배송비',
        detail_25_01: '추가 배송할인',
        detail_26: '최종 결제 금액',
        detail_27: '주문 취소 금액',
        detail_28: '묶음 배송비',
        detail_29: '주문 건수',
        detail_30: '입금 확인 중',
        detail_31: '결제 수단',
        detail_32: '승인 시간',
        detail_33: '결제 금액',
        detail_34: '결제 예정 금액',
        detail_35: '증빙',
        detail_36: '총 결제 금액',
        detail_37: '미결제액',
        detail_38: '환불된 포인트',
        detail_39: '환불된 금액',
        detail_bank_name: '입금은행',
        detail_24_01: '추가 할인',
        detail_40: '택배 수령',
        detail_41: '퀵배송',
        detail_42: '묶음배송',
        detail_43: '방문수령',
        detail_44: '주문 취소 요청이 실패되었습니다.',
        detail_45: '고객님의 주문 취소 요청이 완료되었습니다.<br>확인 후 취소 처리 해드리겠습니다.',
        detail_46: '주문 정보가 변경되어<br>추가 결제가 필요합니다.<br>지금 결제하시겠습니까?',
        detail_47: '구매내역 상세',
        detail_48: '도안',
        detail_49: '옵션',
        detail_50:
          '고객님의 주문 취소 요청이 완료되었습니다.<br>확인 후 취소 처리 해드리겠습니다.<br>실제 환불금 입금까지는<br>최대 1~2주 정도 소요 될 수 있습니다.',
        detail_51: '환불 수수료',
      },
      order_31: '재구매하기',
      order_32: '리뷰쓰기',
      order_29_01: '주문취소',
      order_29_02: '주문 취소 확인 중',
      order_33: '주문번호',
      order_34: '',
      order_35: '주문일자 {{order_date}}',
      order_36: '도안',
      order_37: '인쇄',
      order_38: '색상',
      order_39: '주문이 접수되었습니다. 결제 확인 후 다음 단계로 진행됩니다.',
      order_40: '주문하신 커스텀 사양을 확인하고, 제작을 준비하고 있습니다.',
      order_41: '주문하신 커스텀 상품을 제작하고 있습니다.',
      order_42: '상품 배송을 준비하고 있습니다.',
      order_43: '물품이 발송되어 고객님께 배송중입니다.',
      order_44:
        '배송이 완료되었습니다. 배송 완료일 포함 7일 이내 <a class="underline" href="/kr/help_center?type=order_pay_kr&hg_id=27">교환 / 반품신청</a>이 가능합니다.',
      order_45: '개별 취소',
      order_46:
        '고객센터를 통해 디자인이 수정되어 구매가 불가능해요. [재구매하기]가 가능한 원본 상품을 이용해주세요.',
    },
    wish: {
      wish_01: '찜한 상품',
      wish_02: '나중에 구매할 상품',
      wish_03: '내가 찜한 상품',
      wish_04: '나중에 구매할 상품이 없습니다.',
      wish_05: '나중에 구매할 상품을 {{- br}}원하는 시간에 쉽게 찾아보세요.',
      wish_06: '내가 찜한 상품',
      wish_07: '내가 찜한 상품이 없습니다.',
      wish_08: '나의 취향에 맞는 상품을 찜하고, {{- br}}나만의 커스텀 경험을 즐겨보세요.',
      wish_09: '이런 상품 어떠세요?',
      wish_10: '모두 장바구니에 담기',
      wish_11: '장바구니 담기',
    },
    point: {
      point_01: '포인트',
      point_02: '<span style="color: #FF6B00">{{user_point}}</span> 포인트를 보유하고 있습니다.',
      point_03: '포인트 적립방법',
      point_04: '구매 확정 이후 구매금액의 1%가 적립됩니다.',
      point_05: '나의 리뷰 작성 시 포인트가 최대 300P 지급됩니다.',
      point_06: 'SNS 공유 시 추가로 500P가 지급됩니다.',
      point_07: '포인트 정책',
      point_08: '최소 사용 금액은 제한 없이 1원 단위부터 사용 가능합니다.',
      point_09: '자세한 내용은 {{- FAQ}}에서 확인 가능합니다.',
      point_10: '포인트 적립 및 사용',
      point_11: '날짜',
      point_12: '적립 및 사용 내역',
      point_13: '포인트 적립 및 사용 내역이 없습니다.',
    },
    my_review: {
      my_review_01: '나의 리뷰',
      my_review_02: '작성 가능한 리뷰',
      my_review_03: '내가 작성한 리뷰',
      my_review_04: '작성 가능한 리뷰가 없습니다.',
      my_review_05: '작성한 리뷰가 없습니다.',
    },
    my_info: {
      my_info_01: '계정 설정',
      my_info_02: '기본 회원 정보',
      my_info_03: '이메일 주소',
      my_info_04: '이메일 변경하기',
      my_info_05: '비밀번호',
      my_info_06: '비밀번호 변경 이메일 받기',
      my_info_07: '포인트',
      my_info_08: '문자 소식 받기',
      my_info_09: '이메일 마케팅 수신동의 (선택)',
      my_info_10: '네',
      my_info_11: '안 받을래요',
      my_info_12: '추가 회원 정보',
      my_info_13: '닉네임',
      my_info_14: '닉네임을 입력해 주세요.',
      my_info_15: '생년월일',
      my_info_16: 'YYYYMMDD',
      my_info_17: '이름',
      my_info_18: '휴대전화',
      my_info_19: '- 없이 숫자만 입력해 주세요.',
      my_info_20: '성별',
      my_info_21: '남자',
      my_info_22: '여자',
      my_info_23: '저장하기',
      my_info_24: '회원 탈퇴',
      my_info_25: '이름을 입력해 주세요.',
      my_info_26: '유저 정보가 없습니다. 로그인 후 다시 이용해 주세요.',
      my_info_27: '변경되었습니다.',
      my_info_28: '탈퇴가 되지 않았습니다. 다시 시도해 주세요.',
      my_info_29: '정말 탈퇴하시겠습니까?',
      my_info_30: '저장 실패했습니다.',
    },
    landing: {
      landing_01: '<span>{{name}}님,</span><span>반가워요 👋</span>',
      landing_02: '개인정보 변경',
      landing_03: '구매내역',
      landing_04: '{{count}} 건',
      landing_05: '포인트',
      landing_06: '{{point}} P',
      landing_07: '리뷰',
      landing_08: '{{count}} / <span style="color: #858585">{{all_count}}</span> 개',
      landing_09: '찜한 상품',
      landing_10: '{{count}} 개',
      landing_11: '이 아이템도 커스텀이 가능해요',
      landing_12: '1:1문의',
      landing_13: '계정 설정',
      landing_14: '고객센터',
      landing_15: '로그아웃',
      landing_16: '나의 쇼핑정보',
      landing_17: '고객 서비스',
      landing_18: '',
      landing_19: '장바구니',
      landing_20: '{{count}} 개',
      landing_21: '취소됨',
    },
  },
  cart: {
    cart_01: '장바구니',
    cart_02: '주문서 작성',
    cart_03: '주문 완료',
    cart_04: '일자 / 상품 번호',
    cart_05: '구매 상품정보',
    cart_06: '사이즈 / 수량',
    cart_07: '금액',
    cart_08: '편집',
    cart_09: 'No. {{no}}',
    cart_10: '없음',
    cart_11: '다른 색상 추가하기',
    cart_12: '주문 건수',
    cart_13: '총 상품 금액',
    cart_14: '총 배송비',
    cart_15: '결제 예정 금액',
    cart_16: '주문서 작성하기',
    cart_17: '사이즈와 수량을 선택하지 않은 상품이 있습니다.',
    option: {
      option_01: '수량을 선택해 주세요.',
    },
    cart_18: '장바구니에 담은 상품이 없어요.',
    cart_19: '비어있는 장바구니를 채워주세요!',
    cart_20: '옵션 변경',
    cart_21: '나중에 구매',
    cart_22: '삭제',
    cart_23: '일시 품절',
    cart_24: '단종',
    cart_25: '상품이 품절되었습니다.',
    cart_26: '선택한 옵션 또는 상품이 품절되었습니다.',
    cart_27: '최소 주문 수량({{moq_quantity}}개)이 필요합니다.<br>전체 수량을 알맞게 조절해 주세요.',
    cart_28: '도안',
    cart_29: '주문페이지에서 배송비를 확인해 주세요.',
    cart_30: '수량 선택하기',
    cart_31: '이전',
    cart_32: '상품 추가',
    cart_33: '이미 추가한 색상',
    cart_34: '추가할 수 있는 색상',
    cart_35: '해당 상품의 모든 색상이 장바구니에 담겨있어요.',
    confirm_delete: {
      title: '상품을 삭제하시겠어요?',
      content: '삭제한 상품은 복구할 수 없으며<br>커스텀한 디자인도 저장되지 않습니다.',
      ok: '삭제하기',
      cancel: '취소',
    },
  },
  maker: {
    tooltip: {
      prev_editor_btn: '에디터로 돌아가기',
      complete_btn: '디자인 완료하기',
      mo_title: '잠깐! 디자인을 확인해 주세요.',
      pc_title: '모든 면에 꽉 찬 인쇄를 원하시면<br /><span>이미지를 점선까지 채워주세요.</span>',
      mo_tooltip_text: '점선까지 디자인을 다 채우셨나요?',
    },
    agree_text: '위 내용을 모두 확인했으며, 제작을 진행하겠습니다.',
    warning: {
      image_size: '이미지 사이즈가 조정되었습니다, 확인해 주세요.',
    },
    dosu: {
      confirm1_content:
        '이 상품은 {{dosu_count}}도까지만 인쇄가 가능해요.<br>이 디자인으로 인쇄를 원하시면 풀 컬러 상품으로 이동해 주세요.',
      confirm1_title: '앗! 인쇄 도수를 초과했어요. 😂',
      confirm1_ok: '풀 컬러 상품 만들기',
      confirm1_cancel: '에디터로 돌아가기',
      confirm1_content_mobile:
        '이 상품은 {{dosu_count}}도까지만 인쇄가 가능해요.<br>이 디자인으로 인쇄를 원하시면<br>풀 컬러 상품으로 이동해 주세요.',
    },
    image: {
      title: '내 이미지에서 추출한 색상',
    },
    mobile: {
      landscape_message:
        '세로모드에서 원활한 커스텀이 가능합니다.<br/>화면을 세로로 돌린후 새로고침해 해주세요.',
    },
  },
  biz: {
    dosu: {
      warning_00: '인쇄 가능한 색상으로 자동 변환 됐습니다.',
      warning_01: '기본 제공 색상으로 자동 변환 됐습니다.',
      warning_02: '1가지 색상으로만 인쇄돼요.<br>배경이 투명한 이미지를 넣어주세요.',
      warning_03: '1가지 색상으로만 인쇄돼요.<br>이미지에서 추출한 대표 색상으로 변환됐어요.',
    },
    opt: {
      title: {
        qty: '수량',
        ref_file: '참고파일',
        req_memo: '요청사항',
        print_color: '인쇄색상',
        tippy_content: '주문 수량이 많을수록 할인이 더 커져요!',
        error_message: '현재 인쇄 색상이 초과하였어요. 한 면당 {{dosu_max_ctn}}도 이내로 편집해 주세요.',
        more_dosu: '{{dosu_ctn}}도 (인쇄 색상 초과)',
        embroidery_color: '자수 색상',
      },
      ref_file: {
        placeholder: '상품 제작 시 참고할 파일이 있다면 첨부해 주세요.',
        limit: '파일 유형 : ai, eps 및 pdf 파일 (10MB 이하)',
      },
      req_memo: {
        placeholder: '제작 시 요청사항이 있다면 입력해 주세요',
      },
      ea: '개',
      order: '부터 주문 가능',
      order_unit: '부터 주문 가능 / 주문 단위',
      group_discount: '할인 가격표',
      dosu_guide: '고객님의 디자인에 사용된 색상을 분석해, 인쇄 가능한 색상을 추출해 드려요.',
      file_error: '파일 다운로드 중 오류가 발생했습니다',
      file_size_error: '파일 크기가 제한보다 큽니다.',
      file_ext_error: '가능한 파일 확장자가 아닙니다.',
      order_qty: '주문 수량',
      unit_price: '개당 가격',
    },
    notice: {
      title: {
        price: '상품 가격',
        consulting: '주문 상담',
        payment: '결제 방식',
        delivery: '배송 안내',
        req_consulting: '상담 신청',
      },
      price: '<b>모든 가격은 부가세(VAT)</b> 포함 금액입니다.',
      consulting: '별도 제작 상품, 패키지 및 인쇄 옵션 등 커스텀 주문 희망 시 별도 상담해 주세요.',
      payment: '주문 제작 상품은 선결제를 우선으로 하며, 세금계산서 발행 및 사이트에서 카드 결제 가능합니다.',
      delivery: '개별배송, 창고입고, 퀵발송, 해외배송 등 배송 방식에 따라 추가 요금이 발생 할 수 있습니다.',
      delivery2: '배송 비용에 대한 자세한 상담은 별도 상담해 주세요.',
      req1: '1:1 문의',
    },
    biz_00: '기업 굿즈도 마플에서!',
    biz_01: '기업 굿즈,\n 마플에서 간편하게 해결하세요',
    biz_02:
      '<strong>디자인 시안 확인부터 샘플 제작까지 —</strong>\n효율적인 제작 과정과 편리한 배송 서비스를\n합리적인 가격으로 경험하세요.',
    biz_03: '기업 굿즈 상담하기',
    biz_04: '마플은 다양한 취향과 필요에 맞는\n기업 굿즈를 제공합니다',
    biz_05: '브랜드 혹은 기업의 아이덴티티를 담은 차별화된 굿즈',
    biz_06: '임직원들을 위한 웰컴키트',
    biz_07: '온/오프라인 마케팅 및 홍보를 위한 굿즈',
    biz_08: '마플만의 편리하고 강력한\n커스텀 서비스를 경험해 보세요',
    biz_09: `샘플 제작 가능`,
    biz_10: '마플은 샘플 1개도 제작이 가능합니다.\n특별한 구성품이 필요해도 OK!',
    biz_11: `디자인 시안 확인`,
    biz_12: '마플 사이트에서 상품에 직접 디자인이나 이미지를\n적용해 시안 확인을 실시간으로 할 수 있습니다.',
    biz_13: `소량 제작 가능`,
    biz_14: '적은 수량도 높은 품질로 제작해 드립니다.\n구성품이 많아도 적어도 모두 OK!',
    biz_15: `편리한 배송`,
    biz_16: '국내, 해외 배송부터 개별지정 배송까지 모두 가능합니다.',
    biz_17: '쉽고 빠른 주문 방법으로\n기업 굿즈를 받아보세요!',
    biz_18: '마플 전담 매니저를 통해 디테일한 견적 상담을 진행합니다.',
    biz_19: '제작, 검수, 포장, 배송까지 마플에게 맡겨주세요.\n쉽고 간편한 기업 굿즈가 완성됩니다.',
    biz_20: `마플은 대기업, 스타트업, 학교, 방송사,\n엔터, 게임사들과 함께합니다`,
    biz_21: '어떤 기업 굿즈를\n만들어야 할지 고민이세요?',
    biz_22:
      '웰컴키트 · 프로모션 굿즈 · 브랜드 굿즈 등 목적에 맞는 기업 굿즈들을 모아 놓았습니다.\n추천 굿즈를 직접 확인해 보시고, 편리하게 바로 제작해 보세요!',
    biz_23: '기업 굿즈 추천받기',
    biz_24: '문의하기 {{email}}',
    biz_25: '자주 하시는 질문들\n여기 모아봤어요',
    biz_26: '기업 굿즈 상담하기',
    biz_27: '어떤 기업 굿즈를<br />만들어야 할지 고민이세요?',
    biz_28: '어떤 기업 굿즈를<br />만들어야 할지 고민이세요?',
    biz_29: '기업 굿즈 상담',
    biz_30: '기업 굿즈 상담 신청하기',
    faq: {
      faq_q_1: '굿즈와 키트 차이점이 뭔가요?',
      faq_a_1:
        '굿즈는 주로 단일 아이템/개별 상품을 의미하며 판촉용, 팬 아이템, 기념품, 브랜드 홍보용 제품 등으로 제작합니다.<br/>키트는 여러 개의 구성품이 함께 패키징된 세트를 의미하며, 함께 사용하기 좋거나 특정 목적에 맞도록 기획 및 구성되고 제작됩니다.',
      faq_q_2: '기본 키트 구성을 바꾸거나 구성품 추가가 가능한가요?',
      faq_q_2_mo: '기본 키트 구성을 바꾸거나 구성품 추가가<br/>가능한가요?',
      faq_a_2:
        '가능합니다. 키트 타입을 선택하신 후 기업 굿즈 상담하기를 통해 문의해 주시면, 전담 매니저가 배정되어 요청해 주신 내용을 토대로 상담 도와드리겠습니다.',
      faq_q_3: '최소 주문 수량이 있나요?',
      faq_a_3:
        '키트 제작의 경우 50개 이상 주문 가능합니다. 제품이나 패키지 사양에 따라 최소 주문 수량이 변동될 수 있습니다.<br/>최소 주문 수량 미만의 주문 희망 시 목형비, 인쇄비 과다 청구 등 여러 가지 어려움이 있을 수 있으니, 전담 매니저와 상세 상담 진행해 주세요.',
      faq_q_4: '패키지도 변경 가능한가요?',
      faq_a_4:
        '가능합니다. 구성품이 확정된 후 패키지의 종류와 디자인, 크기 등을 다양하게 선택할 수 있습니다. 패키지 내용 또한 전담 매니저가 상세 상담 도와드리겠습니다.',
      faq_q_5: '키트 견적이 궁금합니다.',
      faq_a_5:
        '구체적인 견적 확인은 전담 매니저와 상담을 통해 확인해 주세요. 주문 수량, 후가공 등 별도 요청 사항에 따라 견적 변동 가능성이 있습니다.<br/>별도 요청 가능 범위는 박스 변경/추가, 커스텀 디자인, 속지, 브랜드 카드 추가, 자사 제품 합포장, 샘플 제작 등이 있습니다.',
      faq_q_6: '제작 기간은 어떻게 되나요?',
      faq_a_6:
        '키트 사양 및 수량에 따라 상이하며, 추천 키트로 제작 시 제작 확정 후 보통 2주 이내로 제작 가능합니다.<br/>구성품이나 사양이 변경되는 경우 1주~2주 더 소요될 수 있습니다.<br/>커스텀 키트의 경우 구성품 및 패킹 사양에 따라 제작 기간이 상이하며, 상담 시 원하시는 수령 일정을 말씀해 주시면 최대한 맞춰서 진행해 드리겠습니다.',
      faq_a_6_mo:
        '굿즈마다 제작 기간이 다를 수 있으며, 최종 제작 승인 후 영업일 기준(주말/공휴일 제외) 7일 정도 소요됩니다. 대량 주문 건의 경우 영업일 기준 2주 이상 소요될 수 있습니다. 샘플 제작 여부 및 제작 내용에 따라 기간이 더 소요될 수 있습니다.<br/>매니저에게 문의를 해주시면 보다 상세한 일정을 안내해 드리겠습니다.',
      faq_q_7: '키트도 샘플 제작이 가능한가요?',
      faq_a_7:
        '구성품이 마플 사이트 상품의 경우 양산 전 샘플 제작이 가능합니다.<br/>시안 확정 후 샘플 수령까지 평균 1주일이 소요됩니다.<br/>그러나 일부 커스텀 제작 굿즈의 경우 제작 특성상 샘플 제작이 어려울 수 있습니다.<br/>샘플 제작이 어려운 굿즈는 사전에 안내해 드립니다.<br/>패키지의 경우 샘플 제작은 어려울 수 있으며, 제작 샘플이나 인쇄 샘플로 확인하시는 방법도 있습니다.',
      faq_q_8: '결제 방법에 대해 알고 싶습니다.',
      faq_a_8:
        '결제는 무통장 입금 (세금계산서 발행)으로 진행됩니다.<br/>입금/결제 확인 후 제작이 진행되는 것이 원칙이오나, 별도 결제/구매 시스템을 통해 결제가 필요하신 경우에는 전담 매니저에게 말씀해 주세요.<br/>기관에 따라 지출 가능하신 날짜가 고정된 경우 등의 예외적인 경우에는 결제일을 협의하여 세금계산서 발행 하에 선제작/후불 결제로 진행할 수 있도록 도와드리고 있습니다.<br/>무통장 입금 시 주문자명과 입금자명이 서로 다를 경우 확인이 불가능하니, 반드시 주문자명으로 입금해 주시기 바랍니다.',
    },
    kit: {
      kit_header_mo: '마플은 다양한 취향과 필요에 맞는<br />기업 굿즈를 제공합니다',
      kit_header_pc: '금액 별 기업 굿즈를<br />추천해 드려요',
      kit_img_alt_1: '반투명 슬라이드 지퍼백, a5 노트, 스티커, 펜, 거울 버튼 이미지',
      kit_title_mo_1: '1만원대<br />세트',
      kit_title_pc_1: '1만원대 세트',
      kit_summary_1: '프로모션 굿즈로 추천!',
      kit_desc_mo_1: '다양한 목적과 장소에서 사용할 수 있으며, <br>홍보하기 좋은 아이템 구성을 소개합니다.',
      kit_desc_pc_1: '다양한 목적과 장소에서 사용할 수 있으며, 홍보하기 좋은 아이템 구성을 소개합니다.',
      kit_point: '특징',
      kit_list_mo_1:
        '<li>판촉물, 프로모션 굿즈로 추천</li><li>오프라인 행사(컨퍼런스, 세미나 등) 굿즈로 추천</li><li>실용성·가성비·휴대성 모두 충족</li><li>100개 기준 가격 (이하 제작 시 별도 상담)</li>',
      kit_list_pc_1:
        '<li>판촉물, 프로모션 굿즈로 추천</li><li>오프라인 행사(컨퍼런스, 세미나 등) 굿즈로 추천</li><li>실용성·가성비·휴대성 모두 충족</li><li>가격은 100개 기준입니다. (이하 제작 시 별도 상담)</li>',
      kit_img_alt_2: '화이트 G형 박스 + 씰링 스티커, 반소매 티셔츠, 스티커, A6 수첩, 펜 이미지',
      kit_title_mo_2: '2만원대<br />세트',
      kit_title_pc_2: '2만원대 세트',
      kit_summary_2: 'ASAP! 가장 빠르게 제작 가능한 구성.',
      kit_desc_mo_2: '빠른 시일 내 제작을 희망하시는<br>분들께 추천드립니다.',
      kit_desc_pc_2: '빠른 시일 내 제작을 희망하시는 분들께 추천드립니다.',
      kit_list_mo_2:
        '<li>신입사원을 위한 웰컴키트로 추천</li><li>사내 이벤트·동아리 단체·기업 행사 굿즈로 추천</li><li>의류와 문구의 적절한 조합</li><li>100개 기준 가격 (이하 제작 시 별도 상담)</li>',
      kit_list_pc_2:
        '<li>신입사원을 위한 웰컴키트로 추천</li><li>사내 이벤트용·동아리 단체·기업 행사 굿즈로 추천</li><li>의류와 문구의 적절한 조합</li><li>가격은 100개 기준입니다. (이하 제작 시 별도 상담)</li>',
      kit_img_alt_3: '화이트 G형 박스 + 씰링 스티커 + A5 스프링 노트, 텀블러, 마우스 패드, 펜 이미지',
      kit_title_mo_3: '3만원대<br />세트',
      kit_title_pc_3: '3만원대 세트',
      kit_summary_3: '데스크 필수템 세트.',
      kit_desc_mo_3:
        '오피스에서 주로 쓰는 유용한 아이템들로<br>센스있게 사용하여 업무 효율을 증가시켜보세요.',
      kit_desc_pc_3: '오피스에서 주로 쓰는 유용한 아이템들로 센스있게 사용하여 업무 효율을 증가시켜보세요.',
      kit_list_mo_3:
        '<li>브랜드 감성이 담긴 차별화된 브랜드 굿즈로 추천</li><li>팬 아이템, 기업 PR용으로 추천</li><li>합리적인 가격으로 만날 수 있는 다양한 상품</li><li>100개 기준 가격 (이하 제작 시 별도 상담)</li>',
      kit_list_pc_3:
        '<li>브랜드 감성이 담긴 차별화된 브랜드 굿즈로 추천</li><li>팬 아이템, 기업 PR용으로 추천</li><li>합리적인 가격으로 만날 수 있는 다양한 상품 구성</li><li>가격은 100개 기준입니다. (이하 제작 시 별도 상담)</li>',
      kit_img_alt_4: '화이트 G형 박스 + 씰링 스티커, 반소매 티셔츠, 코스터, 에코백, 파우치 이미지',
      kit_title_mo_4: '6만원대<br />세트',
      kit_title_pc_4: '6만원대 세트',
      kit_summary_4: '소중한 고객분들을 위한 선물.',
      kit_desc_mo_4:
        '꾸준히 사용할 수 있는 아이템들로 브랜드<br>인지도와 고객 만족도를 높이는데 효과적입니다.',
      kit_desc_pc_4: '꾸준히 사용할 수 있는 아이템들로 브랜드 인지도와 고객 만족도를 높이는데 효과적입니다.',
      kit_list_mo_4:
        '<li>VIP 고객을 위한 선물용 굿즈로 추천</li><li>브랜드 감성이 담긴 차별화된 굿즈로 추천</li><li>실용성·지속성·고급스러움이 가득한 세트</li><li>100개 기준 가격 (이하 제작 시 별도 상담)</li>',
      kit_list_pc_4:
        '<li>VIP 고객을 위한 선물용 굿즈로 추천</li><li>브랜드 감성이 담긴 차별화된 굿즈로 추천</li><li>실용성·지속성·고급스러움이 깃든 정성 가득한 세트</li><li>가격은 100개 기준입니다. (이하 제작 시 별도 상담)</li>',
      kit_img_alt_5: '흰색 핸드폰 케이스, 컵, 티셔츠, 베개, 노트, 에어팟 케이스 이미지',
      kit_title_mo_5: '자유 구성<br />세트',
      kit_title_pc_5: '자유 구성 세트',
      kit_summary_5: '굿즈+패키지 모두 자유롭게 구성',
      kit_desc_mo_5:
        '오피스에서 주로 쓰는 유용한 아이템들로<br>센스있게 사용하여 업무 효율을 증가시켜 보세요.',
      kit_desc_pc_5: '오피스에서 주로 쓰는 유용한 아이템들로 센스있게 사용하여 업무 효율을 증가시켜 보세요.',
      kit_list_mo_5:
        '<li>굿즈와 포장 방식 모두 자유롭게 선택 가능.</li><li>희망 품목+패키징 방식을 기재하셔서</li><li>상담 신청서 작성해 주시거나</li><li><a href="mailto:biz@marpple.com"><u>biz@marpple.com</u></a> 으로 문의 주시면</li><li>상담 가능합니다.</li>',
      kit_list_pc_5:
        '<li>굿즈와 포장 방식 모두 자유롭게 선택 가능합니다.</li><li>희망 품목+패키징 방식을 기재하셔서 상담 신청서 작성해 주시거나</li><li><a href="mailto:biz@marpple.com">biz@marpple.com</a>으로 문의 주시면 상담 가능합니다.</li>',
    },
    kit_form: {
      kit_form_title_mo_1: '기업 굿즈 상담<br />신청 전에 꼭 읽어주세요!',
      kit_form_title_pc_1: '상담 신청 전에<br/>꼭 읽어주세요!',
      kit_form_desc_mo_1:
        '<p>안녕하세요, 마플 B2B 서비스를 담당하고 있는 비즈팀입니다. 마플은 우수한 퀄리티의 단체 주문 커스텀 및 기업 키트 서비스를 합리적인 가격으로 제공하고 있습니다.</p><p>아래 상담 신청서를 통해 전담 매니저의 신속하고 정확한 굿즈 추천 및 제작 상담을 받으실 수 있습니다. 그 외 문의사항은 비즈팀 메일로 문의해 주시기 바랍니다. <a href="mailto:biz@marpple.com">biz@marpple.com</a></p>',
      kit_form_desc_pc_1:
        '<p>안녕하세요, 마플 B2B 서비스를 담당하고 있는 비즈팀입니다.<br />마플은 우수한 퀄리티의 단체 주문 커스텀 및 기업 키트 서비스를<br />합리적인 가격으로 제공하고 있습니다.</p><p>아래 상담 신청서를 통해 전담 매니저의 신속하고 정확한<br />굿즈 추천 및 제작 상담을 받으실 수 있습니다.<br />그 외 문의사항은 비즈팀 메일&nbsp;<a href="mailto:biz@marpple.com"><u>biz@marpple.com</u></a>로 문의해 주시기 바랍니다.</p>',
      kit_form_view_more: '자세히 보기',
      kit_form_info_title: '신청절차 안내',
      kit_form_info_title_1: '1. 상담 접수',
      kit_form_info_desc_mo_1:
        '<p>신청 시 입력하신 메일 주소로 답변 메일이 발송됩니다. (메일 주소를 정확하게 입력해 주세요.)\n상담 신청 시에는 대외비 등 기밀 사항에 해당하지 않는 ‘공개가 가능한 내용’만 서술해 주시기 바랍니다. 상담 내용 및 관련 자료는 상담 검토 및 제작 목적으로만 이용됩니다.</p>',
      kit_form_info_desc_pc_1:
        '<ul><li>신청 시 입력하신 메일 주소로 답변 메일이 발송됩니다. (메일 주소를 정확하게 입력해 주세요.)</li><li>상담 신청 시에는 대외비 등 기밀 사항에 해당하지 않는 ‘공개가 가능한 내용’만 서술해 주시기</li><li>바랍니다. 상담 내용 및 관련 자료는 상담 검토 및 제작 목적으로만 이용됩니다.</li></ul>',
      kit_form_info_title_2: '2. 신청서 검토',
      kit_form_info_desc_mo_2:
        '<p>고객님의 상담 신청은 영업일 기준&nbsp;<u>3~5일이내</u>에 처리됩니다. 담당자가 상세 내용을 검토하는 과정에서 추가 자료가 필요하거나 재문의 할 수 있습니다. (주요 사안의 경우 시간이 조금 더 소요될 수 있으니, 양해 부탁드립니다.)</p>',
      kit_form_info_desc_pc_2:
        '<ul><li>고객님의 상담 신청은 영업일 기준&nbsp;<u>3~5일 이내</u>에 처리됩니다.</li><li>담당자가 상세 내용을 검토하는 과정에서 추가 자료가 필요하거나 재문의 할 수 있습니다.</li><li>(주요 사안의 경우 시간이 조금 더 소요될 수 있으니, 양해 부탁드립니다.)</li></ul>',
      kit_form_info_title_3: '3. 상담 종료',
      kit_form_info_desc_mo_3:
        '<p>담당자의 자료 요청 또는 재문의 후 30일 이내에 고객님께서 회신하지 않는 경우, 해당 상담 신청은 종료 처리됩니다.</p>',
      kit_form_info_desc_pc_3:
        '<ul><li>담당자의 자료 요청 또는 재문의 후 30일 이내에 고객님께서 회신하지 않는 경우,</li><li>해당 상담 신청은 종료 처리됩니다.</li></ul>',
      kit_form_title_2: '상담 유형을<br />선택해 주세요',
      kit_form_select_title_1: '단체굿즈/판촉',
      kit_form_select_desc_1: '별도의 패키지 없이 굿즈 단품(들)만 제작하고 싶어요.',
      kit_form_select_title_2: '패키지 / 키트',
      kit_form_select_desc_2:
        '박스, 틴케이스 등 포장용 아이템만 제작하거나<br />여러 구성품을 합포장하는 키트를 제작하고 싶어요.',
      kit_form_title_3: '상담 신청 내용을<br />작성해 주세요',
      kit_form_required: '필수 입력 항목입니다',
      kit_form_label_1: '신청 제목',
      kit_form_placeholder_1: '제목은 [회사명] 내용 등으로 자유롭게 작성해 주세요.',
      kit_form_placeholder_mo_1: '<p>제목은 [회사명] 내용 등으로 자유롭게 작성해 주세요.</p>',
      kit_form_label_2: '희망 제작 수량',
      kit_form_placeholder_mo_2:
        '<p>단품의 경우 상품명과 함께 각 상품 옵션별(색상,사이즈)<br />수량으로 작성해 주시고, 키트는 세트 기준으로 작성해 주세요.</p>',
      kit_form_placeholder_pc_2:
        '단품의 경우 상품명과 함께 각 상품 옵션별(색상,사이즈) 수량으로 작성해 주시고, 키트는 세트 기준으로 작성해 주세요.',
      kit_form_label_3: '납기일',
      kit_form_placeholder_mo_3:
        '<p>희망하시는 납품 일정을 선택해 주세요.<br />(상품별로 제작 일정은 상이하나, 주문일로부터 제작 완료까지 평균 2주 이상 소요되는 점 참고 부탁드려요.)</p>',
      kit_form_placeholder_pc_3:
        '희망하시는 납품 일정을 선택해 주세요. (상품별로 제작 일정은 상이하나, 주문일로부터 제작 완료까지 평균 2주 이상 소요되는 점 참고 부탁드려요.)',
      kit_form_placeholder_data_3:
        '희망하시는 납품 일정을 선택해 주세요.&#10;(상품별로 제작 일정은 상이하나, 주문일로부터 제작 완료까지 평균 2주 이상 소요되는 점 참고 부탁드려요.)',
      kit_form_label_4: '굿즈 수령지',
      kit_form_placeholder_mo_4:
        '<p>대략적인 주소를 알려주시면<br />배송비까지 함께 안내 받으실 수 있어요.</p>',
      kit_form_placeholder_pc_4: '대략적인 주소를 알려주시면 배송비까지 함께 안내 받으실 수 있어요.',
      kit_form_label_5: '제작 목적',
      kit_form_placeholder_5: '홍보 및 증정용, 판매용 등 제작 목적이나 상황을 작성해 주세요.',
      kit_form_placeholder_mo_5: '<p>홍보 및 증정용, 판매용 등 제작 목적이나 상황을 작성해 주세요.</p>',
      kit_form_label_6: '예산',
      kit_form_placeholder_6: '희망하시는 제작 단가 혹은 정해진 예산이 있다면 기재해 주세요.',
      kit_form_placeholder_mo_6: '<p>희망하시는 제작 단가 혹은 정해진 예산이 있다면 기재해 주세요.</p>',
      kit_form_label_7: '세부 내용',
      kit_form_placeholder_mo_7:
        '전담 매니저가 알아야 할 추가 정보 혹은 마플 사이트에 없는&#10;상품의 경우 참고 URL이 있다면 작성해 주세요.&#10;이미지 등 파일 형태는 아래 첨부 파일에 첨부해 주세요.',
      kit_form_placeholder_pc_7:
        '전담 매니저가 알아야 할 추가 정보 혹은 마플 사이트에 없는 상품의 경우 참고 URL이 있다면 작성해 주세요. 이미지 등 파일 형태는 아래 첨부 파일에 첨부해 주세요.',

      kit_form_title_4: '참고할 파일을<br />첨부해 주세요',
      kit_form_label_8: '첨부 파일',
      kit_form_desc_mo_2:
        'pdf, doc, docx, ppt, pptx, hwp, gul, gif, jpg, png, zip, txt 등 1개의 파일 (100MB 이하의 용량)',
      kit_form_desc_pc_2:
        '첨부파일 형식 : pdf, doc, docx, ppt, pptx, hwp, gul, gif, jpg, png, zip, txt 등 1개의 파일 (100MB이하의 용량)',
      kit_form_title_5: '회사를<br />소개해 주세요',
      kit_form_label_9: '기업 형태',
      kit_form_select_title_3: '일반기업',
      kit_form_select_title_4: '학교/공공기관',
      kit_form_select_title_5: '기타',
      kit_form_label_10: '기업명',
      kit_form_placeholder_10: '회사 혹은 기관 이름을 입력해주세요.',
      kit_form_label_11: '담당자',
      kit_form_placeholder_11: '담당자분의 성함을 입력해주세요.',
      kit_form_label_12: '담당자 이메일',
      kit_form_placeholder_12: '답변 받으실 이메일을 정확하게 입력해주세요.',
      kit_form_placeholder_mo_12: '<p>답변 받으실 이메일을 정확하게 입력해주세요.</p>',
      kit_form_label_13: '담당자 연락처',
      kit_form_placeholder_13: '연락시 받을 수 있는 번호를 정확하게 입력해주세요.',
      kit_form_placeholder_mo_13: '<p>연락시 받을 수 있는 번호를 정확하게 입력해주세요.</p>',
      kit_form_title_6: '개인정보 수집<br /> 및 이용 안내',
      kit_form_label_14: '개인정보 및 수집 이용 동의',
      kit_form_desc_14:
        '(주)마플코퍼레이션은 상담을 희망하는 기업 및 개인을 대상으로 고객 상담을 위해 아래와 같이 개인정보를 수집 및 이용합니다.',
      kit_form_desc_14_2:
        '<li>개인정보 수집 목적 : 고객 상담, 문의사항 전달</li><li>개인정보 수집 항목 : 성명, 전화번호, 이메일</li><li>개인정보의 보유 및 이용 기간 : 개인정보 수집 및 이용 목적이 달성된 후에는 해당 정보를 예외 없이 파기합니다.</li><li>개인정보 및 수집 이용에 동의하지 않을 경우, 상담이 불가합니다</li>',
      kit_form_label_15: '신청 완료하기',
      kit_form_label_16: '신청 완료',
      kit_form_message_1: '파일을 삭제하시겠습니까?',
      kit_form_message_2: '업로드 중...',
      kit_form_message_3: '파일 업로드에 실패했습니다.',
      kit_form_message_4: '오류가 발생했습니다.',
      kit_form_validation_message_1: '올바른 이메일 형식을 입력해 주세요.',
      kit_form_validation_message_2: '올바른 연락처 형식을 입력해 주세요.',
      kit_form_validation_message_3: '상담 유형을 선택해 주세요.',
      kit_form_validation_message_4: '신청 제목은 [회사명] 내용 등으로 자유롭게 작성해 주세요.',
      kit_form_validation_message_5: '희망 제작 수량을 입력해 주세요.',
      kit_form_validation_message_6: '희망하시는 납품 일정을 선택해 주세요.',
      kit_form_validation_message_7: '제작 목적을 입력해 주세요.',
      kit_form_validation_message_8: '세부 내용을 입력해 주세요.',
      kit_form_validation_message_9: '기업 형태를 선택해 주세요.',
      kit_form_validation_message_10: '회사 혹은 기관 이름을 입력해 주세요.',
      kit_form_validation_message_11: '답변 받으실 이메일을 입력해 주세요.',
      kit_form_validation_message_12: '연락처를 입력해 주세요.',
      kit_form_validation_message_13: '개인정보 수집 및 이용에 동의하지 않을 경우 상담이 불가합니다.',
      kit_form_message_5: '업로드 파일 용량은 100 MB 로 제한됩니다',
    },
    kit_form_result: {
      kit_form_result_title: '기업 굿즈 상담 신청 완료',
      kit_form_result_desc:
        '상담 신청이 정상적으로 접수되었습니다.<br />진행 여부를 검토하여 영업일 기준 3~5일 내로 <br />입력하신 메일 주소와 연락처를 통해 연락드리겠습니다.',
      kit_form_result_complete: '완료',
    },
  },
  group_biz_product: {
    gbp_01: '단체 상품 타입',
    gbp_01_mo: '단체 유형',
    gbp_02: '개별 상품 가격',
    gbp_02_mo: '개별 가격',
    gbp_03: '주문 수량',
    gbp_04: '{{price}} 이상',
    gbp_05: '단체굿즈/판촉 상품 더보기',
    gbp_06: '기업 굿즈 상담하기',
    gbp_07: '목적과 예산에 맞는 상품을 찾아보세요!',
    gbp_07_mo: '목적과 예산에 맞는 상품을<br/>찾아보세요!',
    gbp_08: '우리 브랜드에게 필요한 굿즈를 만들어 보세요!',
    gbp_08_mo: '우리 브랜드에게<br/>필요한 굿즈를 만들어 보세요!',
    gbp_09: '단체 주문이 많은 상품이에요',
    gbp_09_mo: '단체 주문이<br/>많은 상품이에요',
    gbp_10: '마플의 \n대표적인 제작 사례를 소개합니다.',
    gbp_11: '단체 주문 추천 카테고리',
    gbp_11_mo: '단체 주문 추천 카테고리',
    biz_keyword: {
      biz_kwd_best: '단체 베스트',
      biz_kwd_1_1: '연말 사은품',
      biz_kwd_1_2: '크루/동호회',
      biz_kwd_1_3: '행사 굿즈',
      biz_kwd_1_4: '사무용품',
      biz_kwd_1_5: '팝업스토어',
      biz_kwd_1_6: '웰컴키트',
      biz_kwd_1_7: '답례품',
      biz_kwd_1_8: '전시회, 박람회',
      biz_kwd_1_9: '소상공인',
      biz_kwd_1_10: '단체티',
    },
    faq: {
      faq_q_1: '굿즈와 키트 차이점이 뭔가요?',
      faq_a_1:
        '굿즈는 주로 단일 아이템/개별 상품을 의미하며 판촉용, 팬 아이템, 기념품, 브랜드 홍보용 제품 등으로 제작합니다.<br/>키트는 여러 개의 구성품이 함께 패키징된 세트를 의미하며, 함께 사용하기 좋거나 특정 목적에 맞도록 기획 및 구성되고 제작됩니다.',
      faq_q_2: '마플 사이트에 없는 굿즈도 제작이 되나요?',
      faq_a_2:
        '가능합니다. 원하시는 굿즈의 디자인과 사양을 제공해 주시면 전담 매니저와 상담하여 제작 가능 여부를 확인하실 수 있습니다.',
      faq_q_3: '굿즈의 견적을 받고 싶습니다.',
      faq_a_3:
        '마플의 모든 굿즈는 주문 제작이므로 굿즈 종류나 수량, 제작 사양에 따라 비용이 상이합니다.<br/>마플 제작 상품의 경우 마플 사이트(www.marpple.com)에서 원하는 상품의 1개 제작 가격을 바로 확인하실 수 있습니다.<br/>의류의 경우, 앞면, 뒷면, 소매 등 프린팅 위치와 크기, 수량, 인쇄 방식(프린팅, 자수)에 따라 제작 단가가 결정됩니다.<br/>따라서 제작 시안을 반드시 제공해 주셔야 정확한 견적이 확인 가능합니다.<br/>커스텀 키트나 별도 주문 제작 굿즈의 경우 전담 매니저에게 문의해 주시면 상담 후 견적을 제공해 드리겠습니다.',
      faq_q_4: '제작 과정에 대해 자세히 알고 싶습니다.',
      faq_a_4: `상담 요청 : 페이지 상단의 '기업 굿즈 상담하기'를 통해 요청해 주세요.
제작 상담 : 전담 매니저가 배정되어 요청해 주신 내용을 토대로 굿즈 추천 및 제작 상담을 제공해 드립니다.
견적 : 상담 후 1차 견적서를 메일로 전송해 드립니다. 이후 시안 및 사양이 확정되면 최종 확정 견적서를 발송해 드립니다.<ul><li>마플 사이트에서 상품에 직접 이미지를 적용하여 시안 확인이 바로 가능합니다.
디자인 완료 후 페이지에서 확인하신 합성 이미지와 함께 디자인 원본 파일(고화질 PNG, AI 또는 PSD 파일)을 전달해 주세요.</li><li>그 외 커스텀 상품 : 별도 디자인 가이드에 맞춰 디자인을 해주시고 디자인 원본 파일(고화질 PNG, AI 또는 PSD 파일)을 전달해 주세요.</li></ul><p>생산 및 배송 : 모든 상품은 전담 매니저의 관리하에 꼼꼼한 검수를 거쳐 높은 퀄리티로 생산되고 안전하게 포장하여 배송해 드립니다.</p>`,
      faq_q_5: '디자인 의뢰도 할 수 있나요?',
      faq_a_5: '제작 시안의 이미지 크기 조정, 배경 제거, 보정 등의 이미지 최적화 작업을 제공합니다.',
      faq_q_6: '샘플 제작이 가능한가요?',
      faq_a_6:
        '마플은 양산 전 샘플 제작이 가능합니다. 그러나 일부 커스텀 제작 굿즈의 경우 제작 특성상 샘플 제작이 어려울 수 있습니다.<br/>샘플 제작이 어려운 굿즈는 사전에 안내해 드립니다. 샘플비는 본 제작 시 1회 지원되며, 본 제작 불발 시 별도 청구됩니다.<br/>샘플 관련 자세한 내용은 전담 매니저와 상담해 주세요.',
      faq_q_7: '제작 기간은 얼마나 걸리나요?',
      faq_a_7:
        '굿즈마다 제작 기간이 다를 수 있으며, 최종 제작 승인 후 영업일 기준(주말/공휴일 제외) 7일 정도 소요됩니다.<br/>대량 주문 건의 경우 영업일 기준 2주 이상 소요될 수 있습니다.<br/>샘플 제작 여부 및 제작 내용에 따라 기간이 더 소요될 수 있습니다.<br/>매니저에게 문의를 해주시면 보다 상세한 일정을 안내해 드리겠습니다.',
      faq_q_8: '결제 방법에 대해 알고 싶습니다.',
      faq_a_8:
        '결제는 주로 무통장 입금(세금계산서 발행) 또는 카드 결제로 진행됩니다.<br/>입금/결제 확인 후 제작이 진행되는 것이 원칙이오나, 별도 결제/구매 시스템을 통해 결제가 필요하신 경우에는 전담 매니저에게 말씀해 주세요.<br/>기관에 따라 지출 가능하신 날짜가 고정된 경우 등의 예외적인 경우에는 결제일을 협의하여 세금계산서 발행 하에 선제작/후불 결제로 진행할 수 있도록 도와드리고 있습니다.<br/>무통장 입금 시 주문자명과 입금자명이 서로 다를 경우 확인이 불가능하니, 반드시 주문자명으로 입금해 주시기 바랍니다.',
      faq_q_9: '배송은 얼마나 걸리나요?',
      faq_a_9:
        'CJ 대한통운 택배사로 출고 후 영업일 기준 1~2일 이내 수령 가능합니다. 단, 도서 산간 지역 및 해당 지역의 택배사 물량에 따라 기간이 좀 더 소요될 수 있습니다<br/>제작 완료된 굿즈 상품들은 방문 수령, 택배, 퀵 등으로 수령 가능합니다.<br/>해외 배송, 도서산간, 항공, 퀵 배송, 개별 배송 등 배송 방법 변경은 전담 매니저와 반드시 상담해 주세요.',
      faq_q_10: '주문 제작한 상품을 교환/반품을 할 수 있나요?',
      faq_a_10:
        '마플의 주문 제작 굿즈는 고객 주문에 따라 개별 제작되는 상품으로 재판매가 불가하므로, 변심에 의한 교환/반품이 불가능합니다.<br/>상품의 문제가 있는 경우 수령 후 7일 이내에 biz@marpple.com으로 상품의 불량에 대한 증빙과 함께 불량 접수를 해주시면 상품 회수 및 검수 후 A/S를 제공해 드립니다.<br/>주문하셨던 상품이 아닌 다른 상품으로의 교환/반품은 불가합니다.<br/>동일 상품, 동일 사이즈, 동일한 색상으로 재제작 및 A/S만 가능하며, 이때 발생되는 배송비는 마플에서 부담합니다.',
    },
  },
  portfolio: {
    pf_btn_01: '포트폴리오 더보기',
    brand_01: 'NEXON',
    brand_01_c: '인턴 제공용 웰컴키트',
    brand_02: 'LG',
    brand_02_c: '로고 단체티',
    brand_03: 'SKT',
    brand_03_c: '행사 문구를 활용한 노트+클립펜',
    brand_04: 'YouTube',
    brand_04_c: '오프라인 행사 배포용 굿즈',
    brand_05: 'kakao games',
    brand_05_c: 'G-STAR 이벤트 굿즈',
    brand_06: 'kakao pay',
    brand_06_c: '임직원 배포용 블랭킷+패키지',
    brand_07: '농심',
    brand_07_c: 'SNS 마케팅 활용 굿즈',
    brand_08: 'SOOP',
    brand_08_c: '스웨이드 캐릭터 쿠션',
    brand_09: 'MARPPLE',
    brand_09_c: '기업 홍보를 위한 웰컴키트',
    brand_10: 'NEXON',
    brand_10_c: '블루아카이브 팝업스토어 판매용 굿즈 70종',
    brand_11: '유네스코한국위원회',
    brand_11_c: 'CI 활용 공식 기념품',
    brand_12: 'KESPA',
    brand_12_c: 'e-sports 수강생 웰컴키트',
    brand_13: 'NEXON',
    brand_13_c: '마비노기 PC방 이벤트 목베개+안대 세트',
    brand_14: 'NEXON',
    brand_14_c: '임직원 자녀를 위한 어린이 학용품 키트',
    brand_15: 'KRAFTON',
    brand_15_c: '전시회 개최 기념 행사 굿즈',
    brand_16: 'NEXON',
    brand_16_c: '마비노기 PC방 이벤트 장패드',
    brand_17: 'NEXON',
    brand_17_c: '클로저스 캐릭터 아크릴 스탠드 한정판',
    brand_18: 'kakao games',
    brand_18_c: '오딘 유저 이벤트 경품용 신년 달력',
    brand_19: 'NEXON',
    brand_19_c: '제주도 넥슨컴퓨터박물관',
    brand_20: 'NEXON',
    brand_20_c: '엘소드 캐릭터 굿즈',
    brand_21: 'NEXON',
    brand_21_c: '마비노기 20주년 타로카드',
    brand_22: '현대자동차',
    brand_22_c: '현대 N 굿즈 패키지',
  },
  about: {
    about_00: '마플에 처음 오셨나요?',
    about_01: '안녕하세요 🎨\n커스텀 굿즈 제작 전문 플랫폼\n마플입니다',
    about_02: `<strong>추억과 우정, 가족과 직장, 개성과 의미, 이름과 생일까지 —</strong>\n마플에서 당신만을 위한, 세상에 단 하나뿐인 상품을 만들어보세요.\n15년간 쌓아온 굿즈 제작 노하우와 편리한 온라인 디자인 솔루션으로\n최고의 커스텀 굿즈를 만들어 드립니다.`,
    about_03: 'Start Creating',
    about_04: '3분만에 완성 가능한 🔫\n마플만의 커스텀 방법 알려드려요',
    about_05: '상품 선택하기',
    about_06: '디자인 업로드하기 (무료 디자인 제공) / 커스텀 굿즈 만들기',
    about_07: '주문하고 결제하면 끝!',
    about_08: `마플의 제작공정\n한 눈에 보기 👀`,
    about_09: `마플에서 만드는 커스텀,\n이렇게 만들어보세요! 🧚`,
    about_10: '세상에 단 하나뿐인 나만의 상품,\n이렇게도 만들 수 있어요! 💞',
    about_11: `내 이미지로 커스텀하기`,
    about_12:
      '내가 직접 만든 로고나 사진을 넣어서 커스텀해 보세요!\n이 세상에 단 하나뿐인 나만의 굿즈 커스텀!',
    about_13: 'Start Creating',
    about_14: `텍스트를 넣어서 커스텀하기`,
    about_15:
      '폰트를 선택하고 내가 원하는 문구를 넣을 수 있어요!\n학교나 단체 이름, 소중한 가족, 내가 원하는 대로!',
    about_16: `무료 꾸미기 디자인으로 커스텀하기`,
    about_17: '디자인이 어려우신가요? 수 천가지에 이르는\n마플 무료 디자인 툴로 빠르고 쉽게 커스텀 완성!',
    about_18: `다양한 방식으로 커스텀하기`,
    about_19: '실크스크린, 디지털 프린팅, 자수, 패치, 형광인쇄,\n다양하게 내 커스텀의 개성을 살려보세요!',
    about_20: `마플에서는 단 한 장부터\n단체주문까지 모두 제작할 수 있어요`,
    about_21: '단체주문',
    about_22:
      '1개부터 주문 제작이 가능한 마플에서 샘플을 먼저 확인 후,\n좋은 품질·높은 퀄리티의 커스텀 제품을 만들어 보세요.',
    about_23: `퀄리티가 고민이세요?\n마플의 프리미엄 퀄리티를 느껴보세요`,
    about_24: '높은 퀄리티',
    about_25:
      '마플은 국내에서 직접 생산하는 높은 퀄리티의 무지 상품과 프린팅 서비스를 제공하고 있습니다.\n디지털 프린팅, 실크 스크린 등 다양한 제작 방식과 설비를 갖추고 있어 다채로운 커스텀이 가능하며,\n다양한 디자인과 디테일을 완벽하게 전달합니다.',
    about_26: `마플은 12만 개의 고객 리뷰로\r 🔍 검증된\n\r 온라인 커스텀 굿즈 \r제작 전문 플랫폼입니다`,
    about_27: '마플러들의 실시간 리뷰',
    about_28: `마플은 대기업, 스타트업, 학교, 방송사,\n엔터, 게임사들과 함께합니다`,
  },
  custom: {
    custom_01: '오늘의 커스텀',
    custom_02: '요즘 인기 커스텀, \r당신도 할 수 있어요!',
    custom_03: '커스텀 Tips',
    custom_04: '커스텀? \r이제 쉬워요!',
    custom_05: '단체상품 가이드',
    custom_06: '단체상품 꿀팁 \r얻어가세요!',
    custom_07: '전체',
    custom_08: '마플 커스텀 노하우 모두 모아봤어요!',
  },
  event: {
    event_01: `이벤트 전체보기`,
  },
  quality: {
    title: 'High Quality',
    qual_01: '믿고 맡기는 마플,\n100% 만족 보장',
    qual_02:
      '마플에서의 고객님들의 경험은 매우 소중하기에\n저희는 최고 수준의 상품을 제작하고\n그 품질을 유지하기 위해 끊임없이 노력하고 있습니다.',
    qual_03: '2,000 여개가 넘는 나만의 상품\n그리고 낭비없는 생산',
    qual_04:
      '마플에서는 세상에서 하나밖에 없는 나만의 상품을 만들 수 있습니다.\n대기업이나 메가 브랜드가 제공하는 획일적 디자인을 벗어나 남들과는 \n다른 나만의 아이템을 찾고있다면 마플이 그 해답입니다.',
    qual_05:
      '공장에서 생산된 후 판매가 되지 않아 버려지거나 폐기되는 옷들이 너무나도 많습니다.\n마플의 전면 인쇄방식은 원단에 먼저 인쇄를 한 이후 재단 및 봉제 과정을 거치기 때문에 \n자원의 낭비를 줄이고, 언제나 환경을 생각하는 마인드로 상품 제작을 실행합니다.',
    qual_06: '최첨단\n직물인쇄 기술',
    qual_07: '디지털\n평판 프린팅',
    qual_08:
      '컴퓨터로 작업 된 이미지를 DTG 방식으로 원단에 직접 인쇄하는 프린팅방식으로 사실적인 표현이 필요한 사진, 복잡한 디자인 또는 다양한 컬러의 표현이 필요할 때 최적화된 인쇄 기법입니다.',
    qual_09: '색상수의 한계가 없음',
    qual_10: '디테일한 이미지와 컬러를 구현',
    qual_11: '초기 셋업 비용이 들지않아 프린팅 주문제작에 최적화',
    qual_12: '실크스크린',
    qual_13:
      '단체티셔츠에 적합한 인쇄방식으로 동일한 디자인, 동일한 크기의 대량작업에 적합한 방식입니다. A2사이즈까지 작업이 가능하며 한 번에 8가지 컬러까지 작업가능합니다.',
    qual_14: '빠른 생산속도',
    qual_15: '동일한 디자인의 대량 생산에 적합',
    qual_16: '세탁에 가장 강력하며 인쇄 퀄리티가 오래 지속됨',
    qual_17: '플랙스',
    qual_18:
      '플렉스 인쇄는 한 두가지 컬러를 인쇄할 때, 이니셜, 등번호 등 단순한 로고작업을 하거나, 소량 개별작업이 필요할 때 좋은 작업방식입니다. 1개의 수량부터 작업이 가능하다는 장점이 있습니다.',
    qual_19: '인쇄면에 티셔츠 원단의 색상이 비치지 않고 고급스러움',
    qual_20: '인쇄 가능 부분 및 면적에 제약이 없음',
    qual_21: '놀라운 세탁 견뢰도',
    qual_22: '승화전사',
    qual_23:
      '전사지에 인쇄 후 출력물을 원단에 고온압착하는 방식입니다. 사실적인 표현이 필요한 사진, 일러스트 등의 이미지를 인쇄하는데 적합하며  의류, 스판덱스, 머그컵, 에코백 등 다양하게 인쇄가 가능합니다.',
    qual_24: '인쇄 가능 부분 및 면적에 제약이 없음',
    qual_25: '인쇄 소재의 제약이 없음 (원단, 머그, 플라스틱소재, 가죽 등)',
    qual_26: '인쇄된 부분을 만져 보았을때 이물감이 전혀 없음',
    qual_27: '전면인쇄',
    qual_28:
      '원단에 먼저 인쇄를 한 후 재단과 봉제를 통해 상품을 제작하는 방식입니다. 마플은 원단 인쇄를 위해 필요한 초대형 프린터와 롤프레스를 갖추고 있어 최고 품질의 결과물을 보장합니다.',
    qual_29: '인쇄 가능 부분 및 면적에 제약이 없음',
    qual_30: '인쇄된 부분을 만져 보았을 때 이물감이 전혀 없음',
    qual_31: '픽셀이 큰 포맷의 인쇄에 적합함',
    qual_32: '자수 인쇄',
    qual_33: '모자, 폴로셔츠, 데님셔츠, 바람막이, 파카 등 다양한 의류에 최고 퀄리티의 자수를 제공합니다.',
    qual_34: '단 1개의 주문에도 자수가 가능함',
    qual_35: '디자인을 의류에 직접 실로 안착시키는 방법으로 고급스러움',
    qual_36: '장점',

    qual_37: '마플이 엄선한 하이퀄리티 브랜드',
    qual_38:
      '마플이 엄선한 브랜드들은 고급 원단, 선명한 색상의 날염, 탄탄한 봉제를 거쳐 내구성이 높으면서도 편안한 착용감을 주는 상품을 제공합니다.\n' +
      '마플은 인쇄에 적합한 원단을 다양한 인쇄방식으로 테스트하여 최고의 퀄리티 제공을 위해 노력하고 있습니다.',
    qual_39:
      '마플이 엄선한 브랜드들은 고급 원단,\n선명한 색상의 날염, 탄탄한 봉제를 거쳐\n내구성이 높으면서도 편안한 착용감을 주는 상품을 제공합니다.',
  },
  group_order: {
    go_01: '단체주문',
    go_02: '10만 개 후기가 증명하는\n단체티의 인기를 경험해 보세요',
    go_03:
      '기업, 모임, 동호회, 학교\n다양한 단체티를 마플에서 경험해 보세요.\n마플에서는 누구나 쉽고 빠르게 만들 수 있습니다.',
    go_04: '단체티 바로 만들기',
    go_05: '마플만의 편리하고 강력한\n단체 주문 서비스를 경험해 보세요',
    go_06: '튼튼한\n퀄리티',
    go_07: '마플 단체티는 우수한 퀄리티의 브랜드 무지 상품으로 제작하여\n내구성이 우수합니다.',
    go_08: '쉽고 빠른\n주문 절차',
    go_09: '마플에서는 디자인부터 견적까지 실시간으로 확인하고 주문이 가능합니다.',
    go_10: '뭉칠수록\n커지는 할인',
    go_11: '마플에서는 5장부터 할인이 시작됩니다.\n주문 수량에 따라 할인 혜택도 더 커집니다!',
    go_12: '놀랍고 기분 좋은\n마플 단체 주문 서비스',
    go_13: '마플에서는 5장부터 할인이 시작됩니다.\n주문 수량에 따라 할인 혜택도 더 커집니다!',
    go_14: '단체 티셔츠',
    go_15: '단체 티셔츠 커스텀 상품 모아봤어요',
    go_16: '브랜드 단체 티셔츠',
    go_17: '브랜드 티셔츠 상품 모아봤어요',
    go_18: '단체 폰케이스',
    go_19: '최신 기종까지 다 모아봤어요',
    go_20: '단체 유니폼',
    go_21: '단체 기능성 의류 모아봤어요',
    go_22: '단체 에코백',
    go_23: '에코백 모두 모아봤어요',
    go_24: '단체 소품',
    go_25: '다양한 단체 아이템 모아봤어요',
    go_26: '단체 주문하기',
    go_27: '지금 바로 단체 주문 할인가를 확인해 보세요!',
    go_28: '전화상담 1566-9437',
    go_29: '상담 가능 시간 평일 10:00-18:00',
  },
  omp_receipt: {
    txt1: '주문 완료',
  },
  omp_title: {
    kit: '마플 기업 굿즈 상담',
    home: '굿즈/단체 상품 제작 플랫폼, 마플',
    new_best: '마플 신상품 / 베스트',
    search: '마플 전체 상품',
    search_result: '{{keyword}} 마플 검색',
    reviews: '마플 실시간 고객리뷰',
    group: '마플 단체 주문',
    know_how: '마플 커스텀 노하우',
    my_page: '마플 마이페이지',
    order: '마플 구매내역',
    wish: '마플 찜한 상품',
    point: '마플 포인트',
    my_review: '마플 나의 리뷰',
    my_review_list: '',
    my_info: '마플 개인정보',
    high_quality: '마플 인쇄 퀄리티',
    event: '마플 이벤트',
    login: '마플 로그인',
    help: '마플 고객센터',
    apparel: '마플 의류',
    sports: '마플 스포츠',
    electronics: '마플 폰케이스/테크',
    accessories: '마플 패션잡화',
    home_deco: '마플 리빙',
    sticker: '마플 스티커/지류',
    frames: '마플 액자',
    stationery: '마플 문구/오피스',
    kids_pet: '마플 키즈/애견',
    new: '마플 신상품',
    best: '마플 베스트',
    biz_best: '단체 베스트',
    review: '마플 리뷰',
    about: '마플 처음 오셨나요?',
    biz: '마플 기업 굿즈',
    etc: '굿즈/단체 상품 제작 플랫폼, 마플',
    sign_up: '마플 회원가입',
    cart: '장바구니',
    order_detail: '주문 상세',
    group_biz: '마플 단체굿즈/판촉',
    goods: '마플 굿즈',
    Cushion: '마플 쿠션/패브릭',
    kids: '마플 키즈',
    pet: '마플 애견',
    digital: '마플 디지털/테크',
    receipt: '구매 완료',
    checkout: '주문서 작성',
  },
  omp_og: {
    title: {
      home: '나만의 커스텀 굿즈부터 단체 상품까지 모두 마플에서',
      apparel: '의류 | 나만의 커스텀, 마플',
      sports: '스포츠 | 나만의 커스텀, 마플',
      electronics: '폰액세서리 | 나만의 커스텀, 마플',
      accessories: '패션잡화 | 나만의 커스텀, 마플',
      home_deco: '리빙 | 나만의 커스텀, 마플',
      sticker: '스티커 | 나만의 커스텀, 마플',
      frames: '액자 | 나만의 커스텀, 마플',
      stationery: '문구/오피스 | 나만의 커스텀, 마플',
      kids_pet: '키드/애견 | 나만의 커스텀, 마플',
      new: 'New | 마플의 새로운 상품을 만나보세요',
      best: 'Best | 마플의 베스트 상품을 만나보세요',
      biz_best: 'Best | 마플의 베스트 상품을 만나보세요',
      review: 'Review | 마플러들의 실시간 리뷰',
      about: 'About | 마플에 처음 오셨나요?',
      biz: '기업 굿즈 상담 | 기업 굿즈 제작, 마플에서 간편하게!',
      etc: '나만의 커스텀 굿즈부터 단체 상품까지 모두 마플에서',
      group: '단체 주문, 마플에서 간편하게!',
      group_biz: '단체굿즈/판촉 | 단체판촉 상품도 이젠 마플에서!',
      goods: '굿즈 | 나만의 커스텀, 마플',
      Cushion: '쿠션/패브릭 | 나만의 커스텀, 마플',
      kids: '키즈 | 나만의 커스텀, 마플',
      pet: '애견 | 나만의 커스텀, 마플',
      digital: '디지털/테크 | 나만의 커스텀, 마플',
    },
    description: {
      default:
        '커스텀티셔츠, 핸드폰케이스제작, 홈데코 소품까지 디자인, 견적 시안 상담 대기 없이 실시간 확인. 마플',
      home: '단, 3분 만에 손쉽게 상품을 만들어보세요!',
      apparel: '다양한 종류의 의류를 나만의 스타일로 커스텀 해보세요.',
      sports: '60여 종의 다양한 스포츠 웨어를 나만의 스타일로 커스텀 해보세요.',
      electronics: '다양한 폰 액세서리들을 나만의 스타일로 커스텀 해보세요.',
      accessories: '다양한 종류의 패션잡화를 나만의 스타일로 커스텀 해보세요.',
      home_deco: '다양한 인테리어 소품들을 나만의 스타일로 만들어 보세요.',
      sticker: '나만의 특별한 스티커는 마플에서! 나만의 스티커를 만들어보세요.',
      frames: '마플에서 오래도록 간직하고 싶은 순간들을 액자로 담아보세요.',
      stationery: '다양한 문구 / 오피스 제품들을 나만의 스타일로 커스텀 해보세요.',
      kids_pet: '우리 집 댕댕이를 위한 커스텀, 마플에서!',
      new: '마플에서 1,000여 종의 다양한 상품들을 만나보세요.',
      best: '마플러들의 Pick! 베스트 상품들을 소개할게요.',
      biz_best: '마플러들의 Pick! 베스트 상품들을 소개할게요.',
      review: '마플 고객들의 10만건이 넘는 후기! 나만의 스타일로 커스텀 해보세요.',
      about: '커스텀 굿즈 및 단체 상품 제작 전문 플랫폼, 마플입니다',
      biz: '디자인 시안 확인부터 샘플 제작까지! 효율적인 제작과정과 편리한 배송 서비스!',
      etc: '단, 3분 만에 손쉽게 상품을 만들어보세요!',
      group: '마플만의 편리하고 강력한 단체 주문 서비스! 뭉칠수록 커지는 할인!',
      group_biz: '디자인 시안 확인부터 샘플 제작까지! 효율적인 제작과정과 편리한 배송 서비스!',
      goods: '다양한 종류의 굿즈를 나만의 스타일로 커스텀 해보세요.',
      Cushion: '나만의 특별한 쿠션은 마플에서! 나만의 쿠션/패브릭 상품을 만들어 보세요.',
      kids: '우리 아이를 위한 키즈 상품 커스텀, 마플에서!',
      pet: '우리 집 댕댕이를 위한 커스텀, 마플에서!',
      digital: '단체 상품으로 제격인 디지털/테크 상품, 마플에서!',
    },
  },
  omp_withdrawal: {
    title: '회원탈퇴',
    txt1: '고객님 마플 ・ 마플샵을 정말 탈퇴하시나요? 😢',
    txt2: '회원 탈퇴 전 아래 사항을 숙지해 주세요.',
    ol_1: '일반 회원인 경우, 회원 탈퇴 후 재가입이 가능하지만 구매하신 컨텐츠 / 주문 내역 / 보유 포인트 등 회원 정보가 모두 삭제됩니다.',
    ol_2: '마플샵 크리에이터인 경우, <strong>진행 중인 거래 0건, 잔여 수익 금액 0원</strong>이어야 하며, 판매 상품의 배송 출고 / CS / 수익 정산이 모두 완료된 뒤 탈퇴가 가능합니다. 탈퇴 후에는 크리에이터샵과 상품 판매 / 정산 내역 등 회원 정보가 모두 삭제되며 복구할 수 없습니다.',
    ol_3: '탈퇴하시더라도 이미 구매하신 상품은 정상 배송 처리되며, 고객님의 개인 정보는 회원 탈퇴 후에도 상품 반품 및 A/S를 위해 「전자상거래 등에서의 소비자 보호에 관한 법률」에 의거한 마플・마플샵 고객 정보 보호 정책에 따라 관리됩니다.',
    ol_4: '탈퇴 진행이 어려우신 경우, 고객센터(<a href="tel:1566-9437">1566-9437</a> 또는 <a href="mailto:help@marpple.com">help@marpple.com</a>)로 문의해 주시면 안내해 드리겠습니다.',
    info_txt1: '진행 중인 거래',
    info_txt2: '{{count}}건',
    info_txt3: '잔여 수익 금액',
    info_txt4: '{{amount}}원',
    info_txt5: '보유 포인트',
    info_txt6: '{{point}}P',
    txt3: '<strong>진행 중인 거래 0건, 잔여 수익 금액 0원</strong>이어야만 탈퇴가 가능합니다.',
    txt4: '자세한 사항은 크리에이터 스튜디오에서 확인 부탁드리며 진행이 어려우신 경우, 고객센터로 문의해 주시면 안내해 드리겠습니다.',
    btn1: '탈퇴하기',
    txt5: '그동안 MCO를 이용해 주셔서 감사합니다.',
    txt6: '보다 나은 서비스로 고객님을 다시 뵐 수 있도록 노력하겠습니다.',
    confirm_txt1: '회원 탈퇴 완료',
    confirm_txt2:
      '그동안 MCO를 이용해 주셔서 감사합니다.<br/>보다 나은 서비스로 다시 뵐 수 있도록<br/>노력하겠습니다.',
    not_confirm_txt1: '회원 탈퇴를 완료할 수 없습니다.',
    not_confirm_txt2: `진행 중인 거래 '0건', 잔여 수익 금액 '0원'이어야만 회원 탈퇴가 가능합니다.<br/>자세한 사항은 마플샵 스튜디오에서 확인 부탁드리며 진행이 어려우신 경우, <a href="tel:{{tel}}" >{{tel}}</a> 또는 <a href="mailto:{{email}}">{{email}}</a>로 문의해 주시면 안내해 드리겠습니다.`,
    confirm_title: '정말 탈퇴하시겠어요?',
    confirm_txt3: '모든 회원 정보는 즉시 삭제되며, 복구가 불가합니다. 계속해서 탈퇴를 진행하시겠습니까?',
    cancel: '취소',
  },
  translateUnit: {
    개: '개',
  },
  maker_upload_type: {
    no_file: '앗! 업로드된 파일이 없어요 😅',
    '만들기 툴은 곧 제공 예정이에요!': '만들기 툴은 곧 제공 예정이에요!',
    '디자인을 저장하지 않으면 변경사항이 사라져요!': '디자인을 저장하지 않으면 변경사항이 사라져요!',
    preview_text:
      '<div>템플릿을 다운로드하시고</div><div>도안을 제작해 나만의 멋진 {{cate_item_name}}를 만들어 보세요!</div>',
  },
  pdf: {
    uploader: {
      0: '디자인 파일 업로드',
      1: '도안업로드',
      2: '템플릿을 다운로드하시고<br>도안을 제작해 나만의 멋진 상품을 만들어 보세요!',
      3: '템플릿은 어도비 일러스트레이터 CS6 부터 사용 가능 합니다.',
      4: '상품의 품질과 원활한 진행을 위해 템플릿 파일을 이용해 디자인해주세요.',
      5: '업로드 가능한 첨부 파일 형식은 PDF이며 최대 100MB 이하의 용량을 지원합니다.',
      6: '이 상품은 주문에 따라 개별적으로 제작되므로 결제 후 주문 취소 및 옵션 변경이 불가합니다.',
      7: '디자인 파일과 옵션을 꼼꼼히 확인해 주세요.<br>작업 기준에 맞지 않는 파일을 접수하여 발생한 인쇄사고는 주문 취소, 환불, 무료 재제작이 불가능합니다.',
      8: '업로드 가능한 첨부 파일 형식은 PDF이며<br>최대 100MB 이하의 용량을 지원합니다.',
      9: '디자인 파일이 접수되었습니다!',
      10: '파일 보관 기간',
      11: '템플릿을 사용하여 제작한 파일입니다.',
      12: '템플릿 다운로드',
      13: '업로드하신 파일이 삭제됩니다. 에디터를 종료하시겠습니까?',
      14: '개월',
      15: '마플이 제공한 최신 템플릿을 사용하지 않았을 경우<br>파일 재접수 필요 여부에 따라 제작이 지연될 수 있습니다.',
      16: '마플의 최신 템플릿을 사용해주세요.',
      17: '템플릿을 사용하여 제작한 파일입니다.',
      18: '취소',
      19: '완료',
      20: '파일을 업로드 하시기 전에 꼭 읽어 주세요!',
      21: '파일 업로드',
      invalid_template: '마플 최신 템플릿을 사용한 파일만 접수 가능합니다.',
      invalid_template2: '마플 템플릿을 사용한 파일만<br>접수가 가능합니다.',
      invalid_template3: '마플 템플릿을 다운로드 받으시거나<br>디자인 방식 → 디자인하기를 이용해 주세요.',
    },
    expired: {
      1: '파일 보관 기간<br />만료',
      2: '파일 보관 기간 만료',
      3: '보관 기간<br/>만료',
      4: '파일 보관 기간이 만료되었습니다.',
      5: '파일 보관 기간이 만료된 상품이 있어요.',
      6: '파일 보관 기간이 만료되었어요.',
      7: '파일 보관 기간이 만료되었습니다.<br/>옵션을 다시 선택해 주시고,<br/>파일을 재업로드 해주세요.',
      8: '파일 보관 기간이 만료되었습니다.<br />옵션을 다시 선택해 주시고, 파일을 재업로드 해주세요.',
      9: '이전 주문 옵션',
      10: '확인',
      11: '파일 보관 기간이 만료된<br/>상품이 있어요.',
      12: '업로드하신 파일은 최종 업로드일로부터<br>6개월 간 보관됩니다.<br/>해당 상품의 커스텀 화면으로 이동 후,<br/>파일을 재업로드 해주세요.',
      13: '업로드하신 파일은 최종 업로드일로부터 6개월 간 보관됩니다.<br/>해당 상품의 커스텀 화면으로 이동 후, 파일을 재업로드 해주세요.',
      14: '이전 화면으로 돌아가기',
    },
    resubmit: {
      1: '재접수 완료',
      2: '재접수하기',
      3: '수정사항 체크',
      4: '도안 업로드',
      5: '재접수 필요',
      6: '재접수가 필요한 파일이 있습니다.',
    },
    agreements: {
      1: '파일 업로드형 주문 제작 상품 동의',
      2: '주문에 따라 개별적으로 생산되는 상품으로 결제 후 주문 취소 및 옵션 변경이 불가합니다.<br>디자인 파일과 옵션을 한 번 더 확인해 주세요. 작업 기준에 맞지 않는 파일을 접수하여 발생한 인쇄사고는 주문 취소, 환불, 무료 재제작이 불가능합니다.<br>업로드 하신 도안 파일은 최종 업로드일로부터 6개월 간 보관됩니다.',
      3: '주문에 따라 개별적으로 생산되는 상품으로 결제 후 주문 취소 및 옵션 변경이 불가합니다. 디자인 파일과 옵션을 한 번 더 확인해 주세요. 작업 기준에 맞지 않는 파일을 접수하여 발생한 인쇄사고는 주문 취소, 환불, 무료 재제작이 불가능합니다. 업로드 하신 도안 파일은 업로드일로부터 6개월 후 삭제됩니다.',
      4: '디자인을 한 번 더 확인해 주세요.',
      5: '파일 업로드형 상품 제작 시 주문취소 및 옵션변경이 불가하니<br />디자인을 한 번 더 확인해 주세요.<br />작업 기준에 맞지 않는 파일로 접수하여 발생한 인쇄사고 건은<br />주문취소, 환불, 무료 재제작이 불가능합니다.',
      6: '파일 업로드형 상품 제작 시 주문취소 및 옵션변경이 불가하니 디자인을 한 번 더 확인해 주세요.<br/>작업 기준에 맞지 않는 파일로 접수하여 발생한 인쇄사고 건은 주문취소, 환불, 무료 재제작이 불가능합니다.',
      7: '확인했습니다.',
    },
  },
  NewMaker: {
    Alert: {
      useLatestPdfVersion: {
        title: '마플의 최신 템플릿을 사용해주세요.',
        content:
          '마플이 제공한 최신 템플릿을 사용하지 않았을 경우<br>파일 재접수 필요 여부에 따라 제작이 지연될 수 있습니다.',
        ok: '접수 진행하기',
        cancel: '에디터로 돌아가기',
        content_mobile:
          '마플이 제공한 최신 템플릿을<br>사용하지 않았을 경우<br>파일 재접수 필요 여부에 따라<br>제작이 지연될 수 있습니다.',
      },
    },
  },
  projection: {
    status: {
      '이미 제작이 시작되어 취소가 어렵습니다. 고객센터로 연락부탁드립니다.':
        '이미 제작이 시작되어 취소가 어렵습니다. 고객센터로 연락부탁드립니다.',
    },
  },
  material_info: {
    title: '재질 안내',
    name: {
      1: '금광데드롱',
      2: '은광데드롱',
      3: '금무지',
      4: '은무지',
      5: '홀로그램',
      6: '트레이싱지',
      7: '아트지',
      8: '모조지',
      9: '스노우지',
      10: '반누보화이트',
      11: '유포지',
      12: '투명데드롱',
      13: 'PVC',
      14: '크라프트지',
      15: '유광코팅',
      16: '레인보우코팅',
      17: '무광코팅',
      18: '스파클코팅',
      19: '리무버블아트지',
      20: '강접아트지',
      21: '강접아트지 유광코팅',
      22: '아르떼',
    },
    description: {
      1: '광택이 있는 금속 질감의 필름으로, 방수효과가 있습니다. 인쇄 잉크 위로 필름의 금색이 올라와 노랗게 표현됩니다. 정확한 색 표현을 원하시면 백색 인쇄를 추가해주세요.',
      2: '광택이 있는 금속 질감의 필름으로, 방수효과가 있습니다. 인쇄 잉크위로 필름의 은색이 올라와 디자인 컬러가 다소 흐리게 표현됩니다. 정확한 색 표현을 원하시면 백색 인쇄를 추가해주세요.',
      3: '반광이 있는 금색 종이로 손으로 찢을수 있으며, 금광 데드롱에 비해 반사광이 부드럽습니다. 인쇄 잉크위로 금색이 올라와 디자인 컬러가 다소 노랗게 표현됩니다. 정확한 색 표현을 원하시면 백색 인쇄를 추가해주세요.',
      4: '반광이 있는 은색 종이로 손으로 찢을수 있으며, 은광 데드롱에 비해 반사광이 부드럽습니다. 인쇄 잉크위로 은색이 올라와 디자인 컬러가 다소 흐리게 표현됩니다. 정확한 색 표현을 원하시면 백색 인쇄를 추가해주세요.',
      5: '광택이 있는 금속 질감의 필름으로, 방수효과가 있습니다. 인쇄 잉크위로 필름의 은빛이 올라와 디자인 컬러가 /다소 연하게 표현됩니다. 정확한 색 표현을 원하시면 백색 인쇄를 추가해주세요.',
      6: '기름종이로 많이 알려진 용지로 안개처럼 뒷면이 비치는 특수용지 입니다. 흠집이 나면 티가 잘 나고 후가공이 어려운 섬세한 용지로, 떡메모 가공시 일반 모조지보다 점착력이 약할 수 있습니다.',
      7: '균일하고 촘촘한 펄프에 표면 후처리를 하여 자체 광택감이 있으며 색상표현이 뛰어납니다. 다양한 후가공을 우수하게 소화하여 스티커, 행택, 명함, 책 표지 등에 두루 사용됩니다.',
      8: '모조지는 매트한 질감이 특징입니다. 표면에 광택이 없으며 A4용지와 비슷한 느낌으로 펜, 연필 필기가 가능합니다. 잉크 흡수량이 많은 편으로 아트지에 인쇄할 때보다 색이 빠진 것처럼 느껴질 수 있습니다.',
      9: '아트지와 더불어 대중적으로 가장 많이 사용되는 용지입니다. 아트지 보다 광택이 없어 차분한 느낌을 주며 모조지 보다 표면이 매끈하고 색상표현이 뛰어납니다. 표지, 명함, 행택 등 다양한 용도로 사용됩니다.',
      10: '표면에 광택이 없는 부드러운 흰색펄프로 인쇄시 깔끔하고 고급스러운 느낌을 줍니다. 고급지 중 가장 인기있는 재질 중 하나입니다. ',
      11: '인쇄를 위한 특수 코팅이 되어있는 필름 스티커입니다. 색상 표현이 좋고 습기 있는 표면에도 잘 부착되어 냉장 식품 등의 라벨에 많이 사용됩니다. 탄성이 없는 편으로 굴곡면 부착 시 주름이 발생할 수 있습니다.',
      12: '물에 젖지 않고 잘 찢어지지 않는 필름 재질입니다. 투명한 소재 특성상 인쇄 영역에 부착면의 색상이 비칩니다. 백색 인쇄를 추가 하면 백색잉크를 깔고 컬러 인쇄하여 바탕색 비침을 막아 또렷한 색표현이 가능합니다.',
      13: '재질에 탄성이 있어 부착면이 열에 의한 팽창, 수축에 강해 스티커가 훼손되지 않습니다. 떼어낸 점착 면에 끈끈이가 잘 남지 않아 노트북 스티커, 차량스티커, 캐리어 스티커 등에 추천합니다.',
      14: '표백 공정을 거치지 않아 천연 펄프의 색상과 티끌이 보이는 친환경 종이입니다. 인쇄 시 잉크 위로 종이 색이 비칩니다. 자연스러운 비침을 원한다면 그대로, 또렷한 색상 표현을 원한다면 백색 인쇄를 추가하세요.',
      15: '많은 분들께 사랑받는 가장 기본적인 코팅입니다. 코팅이 없을 때보다 색이 선명하게 표현되고 빛을 받으면 반사광이 생깁니다. 잉크의 벗겨짐을 방지하고, 표면에 방수처리가 되어 내구도를 높여줍니다.',
      16: '금색 광택이 뛰어난 메탈 느낌의 재질로 내수성이 강해 물에 젖거나 잘 찢어지지 않습니다. 발색이 잘되어 바탕에 색상을 넣어 인쇄하면 화려한 느낌을 살릴 수 있으나 스크래치에 약해 주의가 필요합니다.',
      17: '코팅이 없을 때보다 색이 선명하게 표현되고 은은한 반사광이 생겨 고급스러운 느낌을 줍니다. 스크래치가 생기면 티가 잘 나는 편입니다. 잉크의 벗겨짐을 방지하고, 표면에 방수처리가 되어 내구도를 높여줍니다.',
      18: '코팅이 없을 때보다 색이 선명하게 표현되며 빛을 받는 각도에 따라 조각 홀로그램이 오색으로 빛나는 글리터 코팅입니다. 잉크의 벗겨짐을 방지하고, 표면에 방수처리가 되어 내구도를 높여줍니다.',
      19: '균일하고 촘촘한 펄프에 표면 후처리를 하여 자체 광택감이 있으며 색상 표현이 뛰어납니다. 특수 스티커 가공을 하여 스티커가 찢어지지 않는 한 탈부착 재사용이 가능합니다.',
      20: '아트지 용지 뒷면에 강력 스티커 가공을 했습니다. 아트지는 균일하고 촘촘한 펄프에 표면 후처리를 하여 자체 광택감이 있는 용지로 색상 표현이 뛰어납니다.',
      21: '강접아트지 스티커 표면 유광 필름을 코팅합니다. 코팅이 없을 때보다 색이 선명하게 표현되고 반사광이 생깁니다. 표면 방수는 되지만 옆면은 방수가 되지 않으니 방수 기능을 원하시면 방수 스티커를 구매해주세요.',
      22: '맑은 흰색의 비 코팅지로 색 재현성이 뛰어납니다. 평량 대비 두께감이 좋고 부드러운 표면감과 은은한 색상으로 여러 디자인에 두루 잘 어울리는 인쇄 전용 종이입니다.',
    },
    info: {
      1: {
        title: '상세 사양',
        content: {
          1: '은광 데드롱 스티커 50g',
          2: '필름 코팅 또는 UV 코팅',
          3: '금광 데드롱 스티커 50g',
          4: '은무지 50g',
          5: '홀로그램 데드롱 50g',
          6: '금무지 50g',
          7: '트레이싱지 90g',
          8: '스티커(80g), 행택(250g) 등',
          9: '스노우지 250g',
          10: '반누보 206g',
          11: '유포 필름 80g',
          12: '투명 데드롱 50g 스티커',
          13: 'PVC 120g',
          14: '표백 없이 제작한 친환경 용지',
          15: '필름 코팅',
          16: '리무버블 아트지 스티커 90g',
          17: '강접 아트지 스티커 80g',
          18: '강접 아트지 스티커 80g + 유광 필름 코팅',
          19: '아르떼 울트라 화이트 230g',
        },
      },
      2: {
        title: '특징',
        content: {
          1: '은광 금속 재질 느낌의 필름 스티커',
          2: '깨끗하고 선명한 느낌',
          3: '은색 무광 재질의 종이 스티커',
          4: '금색 금속 재질 느낌의 필름 스티커',
          5: '금색 무광 재질의 종이 스티커',
          6: '뒷면이 비치는 도톰한 반투명 종이',
          7: '자체 광택이 있는 종이',
          8: '빛반사가 없는 비코팅 용지',
          9: '은은한 반사광의 백색 용지',
          10: '색상 표현이 좋은 매트지',
          11: '물에 강하고 잘 찢어지지 않는 백색 필름',
          12: '물에 젖지 않는 투명 필름',
          13: '끈끈이가 잘 남지 않는 유연한 필름 스티커',
          14: '자연스러운 색상과 티끌',
          15: '화려한 무지개빛 반사광',
          16: '은은하고 부드러운 빛 반사',
          17: '화려한 글리터 반사광',
          18: '접착력이 약한 종이 스티커',
          19: '접착력이 강한 종이 스티커',
          20: '표면 방수가 되는 강한 접착력의 스티커',
          21: '약간의 종이결이 느껴지는 매트한 종이',
          22: '무지개색 반사광이 생기는 필름 스티커',
        },
      },
      3: {
        title: '표면 질감',
        content: {
          1: '매끈한 재질',
          2: '매끈한 질감',
          3: '부드러운 질감',
          4: '부드러운 재질',
          5: '다소 거친 질감',
        },
      },
      4: {
        title: '필기',
        content: {
          1: '불가능',
          2: '유성펜',
          3: '시간에 따라 번질 수 있습니다.',
          4: '네임펜 / 연필',
          5: '유성펜 / 볼펜 / 네임펜 / 연필 등',
          6: '스티커(80g), 봉투, 떡메모지(100g) 등',
          7: '유성펜 / 수성펜 / 연필',
          8: '유성펜 / 볼펜 / 연필',
          9: '유성펜 / 볼펜',
          10: '유성펜 / 연필 / 볼펜 등',
          11: '유성펜 / 연필',
          12: '연필',
        },
      },
    },
  },
  modify_info: {
    title: '수정사항 안내',
    1: {
      name: '폰트 윤곽선',
      description:
        '폰트(서체)를 사용하셨다면 반드시 아웃라인화(Create Outline) 해주세요. <br> 제작 기기에 해당 폰트가 설치돼 있지 않다면 굴림체, 바탕체 같은 기본 폰트로 출력됩니다.',
      content_1: "방법 1. 텍스트 오브젝트 우클릭 > '윤곽선 만들기'",
      content_2: '방법 2. 텍스트 오브젝트 클릭 > Ctrl + Shift + O 단축키 사용',
    },
    2: {
      name: 'PDF 저장 옵션',
      description:
        '반드시 아래 옵션으로 도안을 PDF로 저장해 주세요.<br>작업에 맞춘 옵션으로 저장해주셔야 원활한 제작 진행이 가능합니다.',
      content_1:
        '파일 > 다른 이름으로 저장 > 내 컴퓨터에 저장 (파일 포맷 PDF) <br> 1. Adobe PDF 사전 설정  [PDF/X-4:2008(Japan)] 을 선택 <br> 2. [페이지 축소판 포함], [상위 레이어 에서부터 Acrobat 레이어만들기] 체크 <br> 3. PDF 저장',
    },
    3: {
      name: '이미지 임베드',
      description:
        '이미지를 사용하셨다면 꼭 embed 해주세요.<br>디자인에 사용된 이미지를 클릭했을때 X자가 보인다면 이미지가 고객님의 컴퓨터에서만 보이는 상태입니다. ',
      content_1:
        '이미지를 클릭 후 상단 패널에서 [포함] 버튼을 눌러주세요. <br> 여러개의 이미지를 사용하셨다면 모두 동일한 방법으로 이미지를 (Embed)시켜주세요',
    },
    4: {
      name: '사이즈 다름',
      description: `업로드하신 도안이 주문하신 사이즈와 다릅니다.  <br> 템플릿 다운받기 버튼을 눌러, 구매 하신 사이즈에 맞는 도안으로 작업해주세요.`,
    },
  },
  checkout: {
    price_summary: {
      1: '주문 건수',
      2: '총 상품 금액',
      3: '총 배송비',
      4: '결제 예정 금액',
    },
  },
  CustomDetail: {
    1: '추천',
  },
  additional_payment: {
    '결제방법 선택': '결제방법 선택',
    결제하기: '결제하기',
    '추가 결제하기': '',
  },
};
